import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { ILoanApplication } from 'src/app/core/services/loan-application/loan-application.model';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { VehicleApiService } from 'src/app/core/services/mobile-api';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';

@Injectable({
	providedIn: 'root'
})
export class VehicleGuard implements CanActivate {
	constructor(
		private loanAppService: LoanApplicationService,
		private routingService: RoutingService,
		private vehicleService: VehicleApiService
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.loanAppService.updateLoanApplication().pipe(
			filter(Boolean),
			switchMap((loanApp: ILoanApplication) =>
				this.vehicleService.getVehicle(loanApp.id).pipe(
					map((vehicle) => {
						if (vehicle?.makeStr) {
							this.routingService.route(RoutingPathsEnum.offerStatus);
							return false;
						}
						return true;
					})
				)
			)
		);
	}
}
