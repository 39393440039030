<div class="income-verification" *transloco="let t">
	<ng-container *ngIf="viewIsReady">
		<div *ngIf="!incomePartiallyVerified">
			<op-masthead
				*ngIf="!incomeVerified"
				[title]="
					t(
						plaidConnectForIncomeVerified
							? newMemberDebitCardEligibility
								? 'INCOME_VERIFICATION.autoVerifyIncomeByConnectingBank'
								: 'INCOME_VERIFICATION.readyToVerifyIncomeAutomatically'
							: 'INCOME_VERIFICATION.notVerified',
						{ name: firstName }
					)
				"
			></op-masthead>
			<op-masthead
				*ngIf="incomeVerified"
				[title]="
					t(
						plaidConnectForIncomeVerified
							? 'INCOME_VERIFICATION.connectBankAccountForAdditional100'
							: 'INCOME_VERIFICATION.verified',
						{ name: firstName }
					)
				"
			></op-masthead>
			<op-content-container class="content">
				<div [ngClass]="{ 'plaid-border': !plaidConnectForIncomeVerified }">
					<op-plaid-bank-transaction
						(next)="onNext($event)"
						(skip)="skipPlaid($event)"
						[sectionType]="sectionType"
						[bankOnly]="sectionType === 'bank'"
						[showBenefits]="!plaidConnectForIncomeVerified"
						[plaidConnectForIncomeVerified]="plaidConnectForIncomeVerified"
						[skipButtonKey]="
							plaidConnectForIncomeVerified ? 'PLAID_CONNECT.doNotConnectBank' : 'PLAID_CONNECT.skipForNow'
						"
						[incomeVerified]="incomeVerified"
						[showIncentive]="showIncentive"
						[newMemberDebitCardEligibility]="newMemberDebitCardEligibility"
						[accountListFilter]="incomeFilter"
					></op-plaid-bank-transaction>
				</div>
				<op-website-terms class="op-mt-10"></op-website-terms>
			</op-content-container>
		</div>
		<div *ngIf="incomePartiallyVerified">
			<op-masthead [title]="t('INCOME_VERIFICATION.partiallyVerified', { name: firstName })"></op-masthead>
			<div>{{ t('INCOME_VERIFICATION.INCOME_SELECTION.details') }}</div>
			<op-content-container class="content">
				<op-income-selection
					[verifiedMonthlyIncomeAmount]="verifiedMonthlyIncomeAmount"
					[statedMonthlyIncomeAmount]="statedMonthlyIncomeAmount"
					(next)="onNext($event)"
				></op-income-selection>
			</op-content-container>
		</div>
	</ng-container>
</div>
