import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
	AbstractControl,
	AsyncValidatorFn,
	FormBuilder,
	FormGroup,
	ValidationErrors,
	Validators
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { isEmpty, noop } from 'lodash';
import { iif, Observable, of, Subscription, timer } from 'rxjs';
import { catchError, map, pairwise, startWith, switchMap, tap } from 'rxjs/operators';
import { MetadataEnum } from 'src/app/core/services/metadata/metadata.model';
import { MetadataService } from 'src/app/core/services/metadata/metadata.service';
import { ConfigApiService, IConfigResult, IMetadata } from 'src/app/core/services/mobile-api';
import { IAdditionalInfo } from 'src/app/core/services/mobile-api/mobile-api.model';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { SessionStorageService, STORAGE_KEYS } from 'src/app/core/services/storage/session-storage.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';
import { referralCodeValidator } from 'src/app/shared/validators/form-validators';

import { LoanApplicationService } from '../../core/services/loan-application/loan-application.service';
import { MobileApiService } from '../../core/services/mobile-api/mobile-api.service';

@Component({
	selector: 'op-loan-purpose',
	templateUrl: './loan-purpose.component.html',
	styleUrls: ['./loan-purpose.component.scss']
})
export class LoanPurposeComponent implements OnInit, OnDestroy {
	@Input() returnToFTR: string;
	@Output()
	saveToFTR = new EventEmitter<any>();
	constructor(
		private formBuilder: FormBuilder,
		private mobileService: MobileApiService,
		private route: ActivatedRoute,
		private loanAppService: LoanApplicationService,
		private metadataService: MetadataService,
		private routingService: RoutingService,
		private tagDataService: TagDataService,
		private sessionStorageService: SessionStorageService,
		private configApiService: ConfigApiService
	) {
		this.createForm(this.formBuilder);
	}

	formGroup: FormGroup;

	purposeList$: Observable<IMetadata[]>;
	returnToSummary: string;
	isPartnerReferral: boolean;
	initialReferralCodeOffered: string;

	private subscription = new Subscription();

	ngOnInit(): void {
		this.returnToSummary = this.route.snapshot.queryParams?.returnToSummary;
		this.isPartnerReferral = this.route.snapshot.queryParams?.isPartnerReferral;
		//In case of ITA referral_code_p will be part of session storage which is set at initialReferralCode here.
		this.initialReferralCodeOffered = this.sessionStorageService.get('referralCode');

		this.mobileService
			.getPlaidRefreshEligibility(
				this.loanAppService.loanApplicationId,
				this.loanAppService.getCurrentApplicant().applicantIndex
			)
			.pipe(
				tap((res) => {
					this.sessionStorageService.set('plaidRefreshEligible', res.eligible);
				})
			)
			.subscribe();
		// Add remove validators based on referralCode length
		this.formGroup
			.get('referralCode')
			.valueChanges.pipe(startWith(''), pairwise())
			.subscribe({
				next: ([prev, next]: [string, string]) => {
					if (isEmpty(prev) && !isEmpty(next)) {
						this.setReferralCodeValidator();
					} else if (prev?.length != 0 && next?.length === 0) {
						this.clearReferralCodeValidator();
					}
				}
			});

		const loanAppSub = this.loanAppService.loanApplication$
			.pipe(
				map((loanApp) => {
					const postalCode = Number(this.loanAppService.getCurrentApplicant()?.homePostalCode);
					return loanApp;
				}),
				map((rsp) => rsp?.loanPurpose)
			)
			.subscribe({
				next: (purpose) => {
					if (Boolean(purpose)) {
						this.formGroup.get('loanPurpose').setValue(purpose);
						this.formGroup.get('referralCode').disable();
						this.formGroup.get('referralCodeConsent').disable();
					} else {
						this.formGroup.get('referralCode').enable();
						this.formGroup.get('referralCodeConsent').enable();
					}

					const referralCode =
						this.initialReferralCodeOffered || this.loanAppService.getCurrentApplicant()?.referralCode;
					if (Boolean(referralCode)) {
						this.formGroup.get('referralCode').setValue(referralCode);
						this.formGroup
							.get('referralCodeConsent')
							.setValue(this.loanAppService.getCurrentApplicant()?.referralCodeConsent);
					}

					this.tagDataService.view(
						{},
						{
							tealium_event: 'personal_info'
						}
					);
				}
			});
		this.subscription.add(loanAppSub);

		const configSub = this.configApiService
			.configPlaidConnectAddressScreenOrderSwapEnabled()
			.pipe(
				tap((res: IConfigResult) => {
					this.sessionStorageService.set(STORAGE_KEYS.PLAID_CONNECT_ADDRESS_SCREEN_ORDER_SWAP_ENABLED, res?.value);
				})
			)
			.subscribe();

		this.subscription.add(configSub);

		this.purposeList$ = this.metadataService.select(MetadataEnum.LoanPurpose);
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	createForm(fb) {
		this.formGroup = fb.group({
			loanPurpose: [null, [Validators.required]],
			referralCode: [null],
			referralCodeConsent: [null]
		});
	}

	referralCodeValidator(): AsyncValidatorFn {
		return (control: AbstractControl): Observable<ValidationErrors | null> => {
			const debounceTime = 500; //milliseconds
			return timer(debounceTime).pipe(
				switchMap(() =>
					iif(
						() => Boolean(control.value.length) && this.initialReferralCodeOffered != control.value,
						this.mobileService.getReferralCodeStatus(control.value),
						of({ success: true, data: { isValid: true } })
					)
				),
				map((rsp) => {
					return rsp.success && rsp.data.isValid ? null : { invalidReferralCode: true };
				}),
				catchError(() => of({ invalidReferralCode: true }))
			);
		};
	}

	setReferralCodeValidator(): void {
		this.formGroup.get('referralCode').setValidators(referralCodeValidator());
		this.formGroup.get('referralCode').setAsyncValidators(this.referralCodeValidator());
		this.formGroup.get('referralCode').updateValueAndValidity();

		this.formGroup.get('referralCodeConsent').setValidators(Validators.requiredTrue);
		this.formGroup.get('referralCodeConsent').updateValueAndValidity();
	}

	clearReferralCodeValidator(): void {
		this.formGroup.get('referralCode').setErrors(null);
		this.formGroup.get('referralCode').clearValidators();
		this.formGroup.get('referralCode').updateValueAndValidity();

		this.formGroup.get('referralCodeConsent').setErrors(null);
		this.formGroup.get('referralCodeConsent').clearValidators();
		this.formGroup.get('referralCodeConsent').updateValueAndValidity();
	}

	navigate(): void {
		if (this.returnToFTR) {
			this.saveToFTR.emit();
		} else if (this.returnToSummary) {
			this.routingService.route(RoutingPathsEnum.summary, { fragment: this.returnToSummary });
		} else {
			this.routingService.routeFromLoanApp();
		}
	}

	onFocus(): void {
		this.formGroup.get('loanPurpose').errors?.required ? this.formGroup.get('loanPurpose').reset() : noop();
	}

	onSubmit(post: IAdditionalInfo): void {
		const loanApp = this.loanAppService.getLoanApp();
		post.referralCode = post.referralCode || '';
		this.mobileService.setAdditionalInfo(post, loanApp.id /*, loanApp.applicantIndex*/).subscribe({
			next: (resp) => {
				this.navigate();
				this.tagDataService.link(
					{
						referral_code: this.formGroup.get('referralCode').value
					},
					{
						tealium_event: 'personal_info'
					}
				);
			}
		});
	}

	private isCaliforniaPostalCode(postalCode: number): boolean {
		const minZipcodeForCA: number = 90001;
		const maxZipcodeForCA: number = 96162;
		return postalCode >= minZipcodeForCA && postalCode <= maxZipcodeForCA;
	}
}
