import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthenticationGuard } from '../core/guards/authentication.guard';
import { LoanApplicationResolver } from '../core/guards/loan-application.resolver';
import { RoutingPathsEnum } from '../core/services/routing/routing.service';
import { AddressComponent } from './address/address.component';
import { CreditAuthComponent } from './credit-auth/credit-auth.component';
import { DebtComponent } from './debt/debt.component';
import { OfferStatusInformationComponent } from './offer-status-information/offer-status-information.component';
import { FastTrackComponent } from './fast-track/fast-track.component';
import { FinancesComponent } from './finances/finances.component';
import { IdentificationComponent } from './identification/identification.component';
import { IdentityConfirmComponent } from './identity-confirm/identity-confirm.component';
import { IncomeComponent } from './income/income.component';
import { LoanPurposeComponent } from './loan-purpose/loan-purpose.component';
import { PlaidConnectComponent } from './plaid-connect/plaid-connect.component';
import { PlaidConnectGuard } from './plaid-connect/plaid-connect.guard';
import { PreApprovalMfaRequiredGuard } from './pre-approval-mfa-required.guard';
import { ReferencesComponent } from './references/references.component';
import { SecuredPersonalComponent } from './secured-personal/secured-personal.component';
import { SecuredPersonalGuard } from './secured-personal/secured-personal.guard';
import { SpousalContactComponent } from './spousal-contact/spousal-contact.component';
import { SummaryComponent } from './summary/summary.component';
import { VehicleEligibilityComponent } from './vehicle-eligibility/vehicle-eligibility.component';
import { VehicleComponent } from './vehicle/vehicle.component';
import { PlaidRefreshComponent } from './plaid-refresh/plaid-refresh.component';
import { PlaidRefreshGuard } from './plaid-refresh/plaid-refresh.guard';
import { VehicleEligibilityQuestionComponent } from './vehicle-eligibility-question/vehicle-eligibility-question.component';
import { VehicleGuard } from './vehicle/vehicle.guard';

const routes: Routes = [
	{
		path: RoutingPathsEnum.loanPurpose,
		component: LoanPurposeComponent,
		canActivate: [AuthenticationGuard, PreApprovalMfaRequiredGuard],
		resolve: { loanApp: LoanApplicationResolver }
	},
	{
		path: RoutingPathsEnum.plaidRefresh,
		component: PlaidRefreshComponent,
		canActivate: [AuthenticationGuard, PlaidRefreshGuard],
		resolve: { loanApp: LoanApplicationResolver }
	},
	{
		path: RoutingPathsEnum.plaidConnect,
		component: PlaidConnectComponent,
		canActivate: [AuthenticationGuard, PlaidConnectGuard],
		resolve: { loanApp: LoanApplicationResolver }
	},
	{
		path: RoutingPathsEnum.address,
		component: AddressComponent,
		canActivate: [AuthenticationGuard],
		resolve: { loanApp: LoanApplicationResolver }
	},
	{
		path: RoutingPathsEnum.creditAuth,
		component: CreditAuthComponent,
		canActivate: [AuthenticationGuard],
		resolve: { loanApp: LoanApplicationResolver }
	},
	{
		path: RoutingPathsEnum.offerStatusInformation,
		component: OfferStatusInformationComponent,
		canActivate: [AuthenticationGuard],
		resolve: { loanApp: LoanApplicationResolver }
	},
	{
		path: RoutingPathsEnum.finances,
		component: FinancesComponent,
		canActivate: [AuthenticationGuard],
		resolve: { loanApp: LoanApplicationResolver }
	},
	{
		path: RoutingPathsEnum.identification,
		component: IdentificationComponent,
		canActivate: [AuthenticationGuard],
		resolve: { loanApp: LoanApplicationResolver }
	},
	{
		path: RoutingPathsEnum.income,
		component: IncomeComponent,
		canActivate: [AuthenticationGuard],
		resolve: { loanApp: LoanApplicationResolver }
	},
	{
		path: RoutingPathsEnum.references,
		component: ReferencesComponent,
		canActivate: [AuthenticationGuard],
		resolve: { loanApp: LoanApplicationResolver }
	},
	{
		path: RoutingPathsEnum.securedPersonal,
		component: SecuredPersonalComponent,
		canActivate: [AuthenticationGuard, SecuredPersonalGuard],
		resolve: { loanApp: LoanApplicationResolver }
	},
	{
		path: RoutingPathsEnum.summary,
		component: SummaryComponent,
		canActivate: [AuthenticationGuard, PreApprovalMfaRequiredGuard],
		resolve: { loanApp: LoanApplicationResolver }
	},
	{
		path: RoutingPathsEnum.vehicle,
		component: VehicleComponent,
		canActivate: [AuthenticationGuard, VehicleGuard],
		resolve: { loanApp: LoanApplicationResolver }
	},
	{
		path: RoutingPathsEnum.vehicleEligibilityQuestion,
		component: VehicleEligibilityQuestionComponent,
		canActivate: [AuthenticationGuard],
		resolve: { loanApp: LoanApplicationResolver }
	},
	{
		path: RoutingPathsEnum.vehicleEligibility,
		component: VehicleEligibilityComponent,
		canActivate: [AuthenticationGuard],
		resolve: { loanApp: LoanApplicationResolver }
	},
	{
		path: RoutingPathsEnum.identityConfirm,
		component: IdentityConfirmComponent,
		canActivate: [AuthenticationGuard],
		resolve: { loanApp: LoanApplicationResolver }
	},
	{
		path: RoutingPathsEnum.spousalContact,
		component: SpousalContactComponent,
		canActivate: [AuthenticationGuard],
		resolve: { loanApp: LoanApplicationResolver }
	},
	{
		path: RoutingPathsEnum.fastTrack,
		component: FastTrackComponent,
		canActivate: [AuthenticationGuard],
		resolve: { loanApp: LoanApplicationResolver }
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class PreApprovalRoutingModule {}
