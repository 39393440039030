import { Injectable } from '@angular/core';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { EventLogApiService } from 'src/app/core/services/mobile-api/event-log/event-log-api-service';
import { RoutingPathsEnum } from 'src/app/core/services/routing/routing.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';
import { RoutingHistoryService } from '../routing/routing-history.service';

export enum EventDataTagTypeEnum {
	'offerEventType' = 'USER_DROP_BEFORE_OFFERS',
	'documentEventType' = 'USER_DROP_BEFORE_DOC_UPLOADED',
	'incomePageEventType' = 'INCOME_VERIFICATION_PAGE',
	'bankPageEventType' = 'BANK_VERIFICATION_PAGE',
	'bannerEventType' = 'PLAID_BANNER',
	'skipEventType' = 'PLAID_SKIP'
}

export enum EventDataTagValueEnum {
	'offerValue' = 'exit_before_offers',
	'documentValue' = 'no_doc_uploaded',
	'incomePageValue' = 'income_page_after_pre_approval',
	'bankPageValue' = 'bank_verify_after_pre_approval',
	'bannerValue' = 'banner_before_pre_approval',
	'skipValue' = 'before_pre_approval'
}

export enum EventDataTagActionEnum {
	'bannerEventAction' = 'Banner View',
	'skipEventAction' = 'Skip'
}

export enum EventDataTagTitleEnum {
	'incomePageTitle' = 'incomeVerificationPage'
}

export enum EventDataTagPlaidEnum {
	'plaidLoadsFirstTime' = 'plaid_connect_first',
	'plaidLoginInitiated' = 'PLAID_LOG_IN_INITIATED',
	'plaidLoginCompleted' = 'PLAID_LOG_IN_COMPLETED',
	'plaidLoads' = 'PLAID_LOADS',
	'plaidConnectedViaBanner' = 'plaid_banner_connect_from',
	'plaidOneClickLaunchedMobile' = 'PLAID_ONE_CLICK_LAUNCHED_MOBILE',
	'plaidOneClickExpiredMobile' = 'PLAID_ONE_CLICK_EXPIRED_MOBILE',

	'plaidEventSelectInstitution' = 'PLAID_EVENT_SELECT_INSTITUTION',
	'plaidEventViewCredential' = 'PLAID_EVENT_VIEW_CREDENTIAL',
	'plaidEventSubmitCredential' = 'PLAID_EVENT_SUBMIT_CREDENTIAL',
	'plaidEventViewOauth' = 'PLAID_EVENT_VIEW_OAUTH',
	'plaidEventOpenOauth' = 'PLAID_EVENT_OPEN_OAUTH',
	'plaidEventHandoff' = 'PLAID_EVENT_HANDOFF',
	'plaidEventIncomeInsightsCompleted' = 'PLAID_EVENT_INCOMING_INSIGHTS_COMPLETED'
}

@Injectable({
	providedIn: 'root'
})
export class EventDataService {
	constructor(
		private loanAppService: LoanApplicationService,
		private eventLogService: EventLogApiService,
		private tagDataService: TagDataService,
		private routingHistoryService: RoutingHistoryService
	) {}

	logEventDataToLoanApplication(event?: any): void {
		let data = {
			applicantId: this.loanAppService.getCurrentApplicant()?.id,
			loanApplicationId: this.loanAppService.loanApplicationId
		};
		if (event) {
			data = { ...data, ...event };
		}
		this.eventLogService.logLoanAppEvent(data).subscribe();
	}

	checkPageDropEvents() {
		let current_page = this.routingHistoryService.getCurrentRoute();
		if (current_page === RoutingPathsEnum.documentSubmit || current_page === RoutingPathsEnum.summary) {
			const event = {
				eventType:
					current_page === RoutingPathsEnum.documentSubmit
						? EventDataTagTypeEnum.documentEventType
						: EventDataTagTypeEnum.offerEventType,
				value1:
					current_page === RoutingPathsEnum.documentSubmit
						? EventDataTagValueEnum.documentValue
						: EventDataTagValueEnum.offerValue
			};
			const tagEvent = {
				tealium_event:
					current_page === RoutingPathsEnum.documentSubmit
						? EventDataTagTypeEnum.documentEventType
						: EventDataTagTypeEnum.offerEventType,
				event_action:
					current_page === RoutingPathsEnum.documentSubmit
						? EventDataTagValueEnum.documentValue
						: EventDataTagValueEnum.offerValue
			};
			this.tagDataService.link({}, tagEvent);
			this.logEventDataToLoanApplication(event);
		}
	}
}
