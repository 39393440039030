import { animate, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { PlaidBannerService } from 'src/app/core/services/plaid-banner/plaid-banner.service';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import {
	EventDataService,
	EventDataTagActionEnum,
	EventDataTagTypeEnum
} from 'src/app/core/services/event-data/event-data.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';
import { PlaidLinkService } from 'src/app/core/services/plaid-link/plaid-link.service';
import { RoutingHistoryService } from 'src/app/core/services/routing/routing-history.service';
@Component({
	selector: 'op-plaid-banner',
	templateUrl: './plaid-banner.component.html',
	styleUrls: ['./plaid-banner.component.scss'],
	animations: [
		trigger('fadeSlideInOut', [
			transition(':enter', [
				style({ opacity: 0, transform: 'translateY(90px)' }),
				animate('300ms', style({ opacity: 1, transform: 'translateY(0)' }))
			]),
			transition(':leave', [animate('300ms', style({ opacity: 0, transform: 'translateY(90px)' }))])
		])
	]
})
export class PlaidBannerComponent {
	show$;
	constructor(
		private plaidBannerService: PlaidBannerService,
		private routingService: RoutingService,
		private eventDataService: EventDataService,
		private tagDataService: TagDataService,
		private plaidLinkService: PlaidLinkService,
		private routingHistoryService: RoutingHistoryService
	) {
		this.show$ = this.plaidBannerService.plaidBanner$;
	}

	connectBank(): void {
		this.routingService.route(RoutingPathsEnum.plaidConnect);
		this.plaidBannerService.hide();
		this.plaidLinkService.setPlaidConnectBannerValue(true, this.routingHistoryService.getCurrentRoute());
	}

	notNow(): void {
		this.plaidBannerService.hide();
		this.logEvents();
	}

	logEvents(): void {
		const plaidEvent = {
			eventType: EventDataTagTypeEnum.skipEventType,
			value1: EventDataTagActionEnum.skipEventAction + '-' + this.routingHistoryService.getCurrentRoute()
		};
		const tagEvent = {
			tealium_event: EventDataTagTypeEnum.skipEventType,
			event_action: EventDataTagActionEnum.skipEventAction + '-' + this.routingHistoryService.getCurrentRoute()
		};
		this.eventDataService.logEventDataToLoanApplication(plaidEvent);
		this.tagDataService.link({}, tagEvent);
	}
}
