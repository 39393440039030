import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { Observable, of } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';
import { SessionStorageService } from 'src/app/core/services/storage/session-storage.service';
import { ContactUsDialogComponent } from 'src/app/shared/components/dialogs/contact-us-dialog/contact-us-dialog.component';
import { FindLocationDialogComponent } from 'src/app/shared/components/dialogs/find-location-dialog/find-location-dialog.component';
import {
	IMessageDialogData,
	MessageDialogComponent
} from 'src/app/shared/components/dialogs/message-dialog/message-dialog.component';

@Injectable({
	providedIn: 'root'
})
export class DialogService {
	constructor(
		private dialog: MatDialog,
		private translocoService: TranslocoService,
		private sessionStorageService: SessionStorageService
	) {}

	/**
	 * open a generic dialog
	 *
	 * @param {IMessageDialogData} data
	 * @param {(rsp: any) => Observable<any>} confirm
	 * @param {() => Observable<any>} cancel
	 * @return {*}  {Observable<any>}
	 * @memberof DialogService
	 */
	public openMessageDialog(
		data: IMessageDialogData,
		confirm: (rsp: any) => Observable<any>,
		cancel: (rsp: any) => Observable<any>
	): Observable<any> {
		return this.openDialog(MessageDialogComponent, { data }, confirm, cancel);
	}

	public openSimpleMessageDialog(data: IMessageDialogData): Observable<any> {
		return this.openMessageDialog(
			data,
			() => of(),
			() => of()
		);
	}

	/**
	 * open a generic error dialog with message
	 *
	 * @param {*} err
	 * @return {*}  {Observable<any>}
	 * @memberof DialogService
	 */
	public openErrorDialog(err: any, data: IMessageDialogData = null): Observable<any> {
		return this.openErrorDialogWithTitle(this.translocoService.translate('DIALOG_MESSAGE.message'), err, data);
	}

	augmentErrorString(message: string): string {
		return message?.replace(
			/(1-888-564-0084|18885640084|8885640084|888-564-0084)/g,
			'<a href="tel:18885640084">1-888-564-0084</a>'
		);
	}
	/**
	 * open a error dialog with title and message
	 *
	 * @param {string} title
	 * @param {*} err
	 * @return {*}  {Observable<any>}
	 * @memberof DialogService
	 */
	public openErrorDialogWithTitle(title: string, err: any, dialogData: IMessageDialogData = null): Observable<any> {
		let message = err;
		let augment = false;
		if (typeof err !== 'string') {
			augment = true;
			message = Array.isArray(err?.error) ? err.error.find(Boolean).msg : err.error;
		}

		if (typeof message === 'string') {
			const data = {
				icon: 'op-alert2',
				title,
				message: augment ? this.augmentErrorString(message) : message,
				...dialogData
			};
			return this.openSimpleDialog(MessageDialogComponent, { data });
		} else {
			console.error('-op-', err);
			return of(true);
		}
	}

	/**
	 * Open a dialog component and return the afterClosed observable.
	 *
	 * @param {ComponentType<any>} component
	 * @param {*} [config={}]
	 * @return {*}  {Observable<any>}
	 * @memberof DialogService
	 */
	public openSimpleDialog(component: ComponentType<any>, config: any = {}): Observable<any> {
		return this.openDialog(
			component,
			config,
			(rsp) => of(rsp),
			(rsp) => of(rsp)
		);
	}

	/**
	 * wrap the dialog open api
	 *
	 * @param {ComponentType<any>} component
	 * @param {*} config
	 * @return {*}  {MatDialogRef<any, any>}
	 * @memberof DialogService
	 */
	public open(component: ComponentType<any>, config: any): MatDialogRef<any, any> {
		return this.dialog.open(component, config);
	}
	/**
	 * Open a dialog component and return the afterClosed observable.
	 *
	 * @param {ComponentType<any>} component
	 * @param {*} [config={}]
	 * @return {*}  {Observable<any>}
	 * @memberof DialogService
	 */
	public openDialog(
		component: ComponentType<any>,
		config: any,
		confirm: (rsp: any) => Observable<any>,
		cancel: (rsp: any) => Observable<any>
	): Observable<any> {
		const dialogRef = this.open(component, config);
		return dialogRef.afterClosed().pipe(switchMap((rsp) => (Boolean(rsp) ? confirm(rsp) : cancel(rsp))));
	}

	/**
	 * Open the find location dialog.
	 *
	 * @return {*}  {Observable<any>}
	 * @memberof DialogService
	 */
	public openFindLocationDialog(): Observable<any> {
		return this.openSimpleDialog(FindLocationDialogComponent);
	}

	/**
	 * Open the contact us dialog.
	 *
	 * @return {*}  {Observable<any>}
	 * @memberof DialogService
	 */
	public openContactUsDialog(isLoanExists: boolean): Observable<any> {
		let phoneNumber = isLoanExists ? 'CALL_US.phoneAfterReservation' : 'CALL_US.phoneBeforeReservation';
		let fullHours = 'CALL_US.fullHours';

		if (Boolean(this.sessionStorageService.get('isSplStoreless'))) {
			phoneNumber = 'STORE_LESS.callUsNumber';
			fullHours = 'STORE_LESS.fullHours';
		}

		return this.openSimpleDialog(ContactUsDialogComponent, { data: { phoneNumber, fullHours } }).pipe(
			finalize(() => this.sessionStorageService.remove('isSplStoreless'))
		);
	}

	/**
	 * Open the message dialog.
	 *
	 * @return {*}  {Observable<any>}
	 * @memberof DialogService
	 */
	public refIdErrorDialog(): Observable<any> {
		const data = {
			title: this.translocoService.translate('REF_ID_ERROR.title'),
			message: this.translocoService.translate('REF_ID_ERROR.description'),
			confirmText: this.translocoService.translate('GLOBAL.ok')
		};
		return this.openMessageDialog(
			data,
			() => of(null),
			() => of(null)
		);
	}

	public openGCPEligibilityErrorDialog(error: any): Observable<any> {
		return this.openMessageDialog(
			{
				title: this.translocoService.translate('GCP_ELIGIBILITY_ERROR.title'),
				message: this.translocoService.translate('GCP_ELIGIBILITY_ERROR.message')
			},
			() => of(null),
			() => of(null)
		);
	}
}
