import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import {
	ProductCategoriesEnum,
	SecuredOfferResponseEnum
} from 'src/app/core/services/loan-application/product-offer/product/product.model';
import { MobileApiService } from 'src/app/core/services/mobile-api/mobile-api.service';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { SessionStorageService } from 'src/app/core/services/storage/session-storage.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';

@Component({
	selector: 'op-offer-status-information',
	templateUrl: './offer-status-information.component.html',
	styleUrls: ['./offer-status-information.component.scss']
})
export class OfferStatusInformationComponent implements OnInit, OnDestroy {
	language: string;
	whatIsSecuredPersonalLoanLink: SafeUrl;

	private subscription = new Subscription();

	constructor(
		private loanAppService: LoanApplicationService,
		private mobileService: MobileApiService,
		private sanitizer: DomSanitizer,
		private translocoService: TranslocoService,
		private routingService: RoutingService,
		private tagDataService: TagDataService,
		private dialogService: DialogService,
		private sessionStorageService: SessionStorageService
	) {}

	ngOnInit(): void {
		const langSub = this.translocoService.langChanges$.subscribe({
			next: (language) => {
				this.language = language;
				this.whatIsSecuredPersonalLoanLink = this.sanitizer.bypassSecurityTrustUrl(
					this.getWhatIsSecuredPersonalLoanLink()
				);
				this.createTealiumEvent('page_load', 'page load', 'view');
			}
		});
		this.subscription.add(langSub);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	checkVehicleEligibility(): void {
		const productCategory: ProductCategoriesEnum = ProductCategoriesEnum.securedPersonalLoan;
		const requestParameters = { productCategory, vehicleEligibilityCheck: 'Yes' };
		this.createTealiumEvent('check_vehicle_eligiblity', 'click', 'link');

		this.mobileService.setProductSelection(requestParameters, this.loanAppService.loanApplicationId).subscribe({
			next: (response) => {
				this.routingService.route(RoutingPathsEnum.vehicle);
			}
		});
	}

	declineSecuredOffer(): void {
		this.createTealiumEvent('go_back_to_offers_status_page', 'click', 'link');
		this.routingService.route(RoutingPathsEnum.offerStatus);
	}

	getWhatIsSecuredPersonalLoanLink(): string {
		if (this.language.toLowerCase() === 'es') {
			return 'https://oportun.com/es/resources/what-is-a-secured-personal-loan';
		}

		return 'https://oportun.com/resources/what-is-a-secured-personal-loan';
	}

	talkToAgent(): void {
		this.createTealiumEvent('call_to_agent', 'click', 'link');
		this.dialogService.openContactUsDialog(Boolean(this.loanAppService.loanApplicationId)).subscribe();
	}

	createTealiumEvent(event: string, eventAction: string, eventType: string): any {
		return this.tagDataService[eventType](
			{
				product_sub_category: this.sessionStorageService.get('productCategorySelection'),
				product_offer_status: this.tagDataService.getTealiumStringForOfferStatus(
					this.loanAppService.getLoanApp().productOfferDetails
				)
			},
			{
				event_action: eventAction,
				event_category: 'CONSUMER_INSTALLMENT_LOAN',
				event_label: event,
				tealium_event: 'offer_status_info_' + event
			}
		);
	}
}
