import { HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { GCP_ELIGIBILITY_FETCHING_FAILED } from 'src/app/shared/constants/common-const';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';

/**
 * error handling for specific error from server response, see if we can handle more errors in the future.
 *
 * Sometimes server may return same error for different api calls, and for the specific error we want wo show error popup.
 * We don't want to have the error on different pages, so we can handle the error here.
 *
 * @export
 * @class ServerErrorInterceptor
 * @implements {HttpInterceptor}
 */

export const IGNORE_SERVER_ERROR_IDS = new HttpContextToken<string[]>(() => []);

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
	constructor(private dialogService: DialogService) {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		const ignoreServerIds = request.context.get(IGNORE_SERVER_ERROR_IDS);
		return next.handle(request).pipe(
			catchError((error) => {
				if (error?.error?.[0]?.id === GCP_ELIGIBILITY_FETCHING_FAILED) {
					if (ignoreServerIds?.length && ignoreServerIds.includes(GCP_ELIGIBILITY_FETCHING_FAILED)) {
						throw error;
					} else {
						return this.dialogService.openGCPEligibilityErrorDialog(error);
					}
				} else {
					throw error;
				}
			})
		);
	}
}
