import { Component, OnDestroy, OnInit } from '@angular/core';

import { combineLatest, map, of, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { isFunction, noop } from 'lodash';

import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import {
	AchBankAccountsService,
	IAchBankAccount
} from 'src/app/core/services/ach-bank-accounts/ach-bank-accounts.service';
import {
	BankConnectEventTypeEnum,
	BankConnectResponseStatusEnum,
	IBankConnectEvent,
	PlaidLinkService
} from 'src/app/core/services/plaid-link/plaid-link.service';
import { SessionStorageService, STORAGE_KEYS } from 'src/app/core/services/storage/session-storage.service';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { AccountNotConnectedComponent } from 'src/app/shared/components/dialogs/account-not-connected/account-not-connected.component';
import { ProductOfferDetailsUtils } from 'src/app/core/services/loan-application/product-offer/product-offer-details/product-offer-details-utils';

@Component({
	selector: 'op-add-bank-account',
	templateUrl: './add-bank-account.component.html',
	styleUrls: ['./add-bank-account.component.scss']
})
export class AddBankAccountComponent implements OnInit, OnDestroy {
	protected subscription = new Subscription();

	protected plaidConnected = false;

	protected accountList: IAchBankAccount[] = null;

	protected nextRoute = RoutingPathsEnum.documentSubmit;

	protected manualBankAccountRoute = RoutingPathsEnum.addManually;

	showNoAccountsError: boolean = false;

	bankConnectEvent: BankConnectEventTypeEnum = BankConnectEventTypeEnum.idle;

	showAddButton: boolean = false;

	showBenefits: boolean = true;

	showIncentive: boolean = false;

	loaded = false;

	constructor(
		protected routingService: RoutingService,
		protected dialogService: DialogService,
		protected bankAccountService: AchBankAccountsService,
		protected tagDataService: TagDataService,
		protected loanAppService: LoanApplicationService,
		protected plaidLinkService: PlaidLinkService,
		protected sessionStorageService: SessionStorageService
	) {
		this.plaidConnected = this.sessionStorageService.get(STORAGE_KEYS.PLAID_CONNECTED);
	}

	ngOnInit(): void {
		const plaidSub = this.plaidLinkService.plaid$.subscribe({
			next: (rsp) => {
				this.onBankConnect(rsp);
			}
		});
		this.subscription.add(plaidSub);

		const loanAppSub = this.loanAppService.loanApplication$.subscribe({
			next: (loanApp) => {
				this.showIncentive = !ProductOfferDetailsUtils.fromLoanApp(loanApp)?.hasOnlySPL();
			}
		});
		this.subscription.add(loanAppSub);

		const bankSub = combineLatest([
			this.bankAccountService.bankAccounts$,
			this.loanAppService.isGCPOrReturnApplicant() ? this.bankAccountService.returningBankAccounts$ : of([])
		])
			.pipe(
				map(([rsp, returningRsp]) => rsp?.concat(returningRsp) || []),
				filter((r) => Boolean(r))
			)
			.subscribe({
				next: (rsp) => {
					this.accountList = rsp;
					this.loaded = true;
				}
			});
		this.subscription.add(bankSub);
	}

	ngOnDestroy(): void {
		this.sessionStorageService.remove(STORAGE_KEYS.PLAID_CONNECTED);
		this.subscription.unsubscribe();
	}

	addManually(): void {
		this.routingService.route(this.manualBankAccountRoute, { skipLocationChange: true });
	}

	hideAccountList($event: boolean): void {
		this.showAddButton = $event;
	}

	onBankConnect(event: IBankConnectEvent): void {
		this.bankConnectEvent = event.type;
		const bankConnectEvents = {
			[BankConnectEventTypeEnum.opened]: this.bankConnectionOpenedCallback.bind(this),
			[BankConnectEventTypeEnum.complete]: this.bankConnectionCompleteCallback.bind(this),
			[BankConnectEventTypeEnum.error]: this.bankErrorCallback.bind(this)
		};
		return isFunction(bankConnectEvents[event?.type]) ? bankConnectEvents[event.type](event) : noop;
	}

	bankConnectionOpenedCallback(event: IBankConnectEvent): void {
		this.showNoAccountsError = false;
	}

	bankConnectionCompleteCallback(event: IBankConnectEvent): void {
		if (event.data.responseStatus === BankConnectResponseStatusEnum.emptyAccountList) {
			this.showPlaidConnectError();
		} else {
			const accountsFound = event?.data?.responseStatus === BankConnectResponseStatusEnum.accountsFound;
			const msgData = this.plaidLinkService.getBankDialogMessage(accountsFound);
			if (msgData) {
				this.dialogService
					.openMessageDialog(
						msgData,
						() => of(),
						() => of()
					)
					.subscribe({
						next: () => {
							this.tagDataService.link(
								{},
								{
									tealium_event: 'bank_not_found'
								}
							);
						}
					});
			}
			this.showAddButton = false;
		}
	}

	protected tealiumEvent(accountFound: boolean, incomeVerified: boolean): string {
		if (!accountFound) {
			return 'bank_not_found';
		}

		if (!incomeVerified) {
			return 'income_not_found';
		}
	}

	bankErrorCallback(event: IBankConnectEvent): void {
		this.showPlaidConnectError();
	}

	showPlaidConnectError(): void {
		this.dialogService.openSimpleDialog(AccountNotConnectedComponent).subscribe({
			next: (result) => {
				if (result?.addManually === true) {
					this.routingService.route(this.manualBankAccountRoute);
				}
				if (result?.addUsingPlaid === true) {
					this.plaidLinkService.openPlaid();
				}
			},
			error: () => {}
		});
	}

	onBankSelected($event: IAchBankAccount): void {
		this.routingService.route(this.nextRoute);
	}
}
