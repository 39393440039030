import { Injectable } from '@angular/core';
import { SessionStorageService, STORAGE_KEYS } from '../storage/session-storage.service';
import { HistoryArray } from './routing-history';
import { RoutingPathsEnum } from './routing.service';

/**
 * Keeps track of History and anything related to it.
 *
 * @export
 * @class RoutingHistoryService
 */
@Injectable({
	providedIn: 'root'
})
export class RoutingHistoryService {
	private readonly storageKey = 'routes';
	private history = new HistoryArray([], 10);

	constructor(private sessionStorageService: SessionStorageService) {
		this.history.routes = this.sessionStorageService.get(this.storageKey) || [];
	}
	/**
	 * returns the current route.
	 *
	 * @return {*}  {RoutingPathsEnum}
	 * @memberof HistoryServiceService
	 */
	getCurrentRoute(): RoutingPathsEnum {
		return this.history.current();
	}

	/**
	 * returns the previous route.
	 *
	 * @return {*}  {RoutingPathsEnum}
	 * @memberof HistoryServiceService
	 */
	getPreviousRoute(): RoutingPathsEnum {
		return this.history.previous();
	}

	addRoute(route: RoutingPathsEnum): void {
		this.history.push(route);
		this.sessionStorageService.set(this.storageKey, this.history.routes);
	}

	removeRoute(): void {
		if (this.history.length > 1) {
			this.history.pop();
			this.sessionStorageService.set(this.storageKey, this.history.routes);
		}
	}

	getLength(): number {
		return this.history.length;
	}
}
