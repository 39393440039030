import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { isFunction } from 'lodash';
import { filter, Subscription } from 'rxjs';
import { OcrEligibilityEnum } from 'src/app/approval/document-submit/guards/auto-eligibility.resolver';
import { NON_CRITICAL_ERRORS } from 'src/app/core/services/new-relic/new-relic-constants';

import { LoanApplicationService } from '../loan-application/loan-application.service';
import { WindowRefService } from '../window-ref/window-ref.service';
import { newRelicFlowEnum, newRelicStartFlowMap } from './new-relic-monitor.model';
import { RoutingHistoryService } from '../routing/routing-history.service';

@Injectable({
	providedIn: 'root'
})
export class NewRelicMonitorService implements OnDestroy {
	private isFlowError: boolean = false;
	private flowName: string = '';

	// List of Non-critical errors that do not break the user flow.
	private readonly nonCriticalErrors: string[] = NON_CRITICAL_ERRORS;

	private subscription = new Subscription();

	constructor(
		private windowService: WindowRefService,
		private router: Router,
		private loanAppService: LoanApplicationService,
		private routingHistoryService: RoutingHistoryService
	) {
		const routingEndSub = this.router.events.pipe(filter((evt: any) => evt instanceof NavigationEnd)).subscribe({
			next: (event) => {
				const currentPage = this.routingHistoryService.getCurrentRoute();

				const flowPage = newRelicStartFlowMap[currentPage];
				const newFlow =
					flowPage?.find((flow) => flow.loanAppStatus === this.loanAppService.applicationStatus) || flowPage?.[0];
				let newFlowName = newFlow?.flowName;

				if (flowPage && newFlowName !== this.flowName) {
					if (
						newFlowName === newRelicFlowEnum.docSubmitOcr &&
						this.loanAppService.getOcrEligibility() === OcrEligibilityEnum.originalExperience
					) {
						newFlowName = newRelicFlowEnum.docSubmit;
					}
					this.startFlow(newFlowName);
				}
			}
		});
		this.subscription.add(routingEndSub);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	/**
	 * Set the Flow Name for the current user flow.
	 *
	 * @param {string} flowName
	 * @memberof NewRelicMonitorService
	 */
	private setFlowName(flowName: string): void {
		this.flowName = flowName;
	}

	/**
	 * Get the Flow Name for the current user flow.
	 *
	 * @return {*}  {string}
	 * @memberof NewRelicMonitorService
	 */
	public getFlowName(): string {
		return this.flowName;
	}

	/**
	 * Set the Flow Error flag to true if an error occurred during the flow.
	 *
	 * @param {boolean} isFlowError
	 * @memberof NewRelicMonitorService
	 */
	private setFlowError(isFlowError: boolean): void {
		this.isFlowError = isFlowError;
	}

	/**
	 * Get the Flow Error flag to check if an error occurred during the flow.
	 *
	 * @return {*}  {boolean}
	 * @memberof NewRelicMonitorService
	 */
	public getFlowError(): boolean {
		return this.isFlowError;
	}

	/**
	 * Starts the flow with the provided flow name.
	 *
	 * @private
	 * @param {string} flowName
	 * @memberof NewRelicMonitorService
	 */
	private startFlow(flowName: string): void {
		this.setFlowName(flowName);
		this.setFlowError(false);
		this.sendToNewRelic(flowName, { result: 'success', loanAppId: this.loanAppService?.loanApplicationId });
	}

	/**
	 * Sends the flow name to New Relic with the attributes provided.
	 * @param action - The name of the page action or flow name
	 * @param attributes - The Additional attributes to include with the flow name. E.g. { result: 'failure' }
	 */
	private sendToNewRelic(action: string = this.flowName, attributes: Object = {}): void {
		if (isFunction(this.windowService.window?.['NREUM'].addPageAction)) {
			this.windowService.window?.['NREUM'].addPageAction(action, attributes);
		}
	}

	/**
	 * Reports the flow to New Relic with the result of the flow.
	 *
	 * @memberof NewRelicMonitorService
	 */
	public reportIfFlowErrors(): void {
		if (this.isFlowError && this.flowName) {
			this.sendToNewRelic(this.flowName, { result: 'failure' });
		} else {
			this.sendToNewRelic(this.flowName, { result: 'abandonment' });
		}
		this.resetFlowError();
	}

	/**
	 * Resets the flow name and error flag.
	 *
	 * @memberof NewRelicMonitorService
	 */
	private resetFlowError(): void {
		this.flowName = '';
		this.isFlowError = false;
	}

	/**
	 * Sets the flow error flag to true if the error is not a non-critical error.
	 *
	 * @param {Error} error
	 * @memberof NewRelicMonitorService
	 */
	public setCriticalError(error: Error): void {
		if (!this.nonCriticalErrors.some((nonCriticalError) => error?.toString()?.includes(nonCriticalError))) {
			this.setFlowError(true);
		}
	}
}
