<div class="plaid-bank-transaction" *transloco="let t">
	<div class="content" *ngIf="initialize">
		<op-plaid-account-list
			*ngIf="accountList?.length && accountListDisplayMode === accountListDisplayModeEnum.readonly"
			(next)="onNext($event)"
			(addBank)="onAddBank($event)"
			[accountList]="accountList"
		></op-plaid-account-list>

		<op-plaid-account-selection-list
			id="verified-accounts"
			*ngIf="accountList?.length && accountListDisplayMode === accountListDisplayModeEnum.select"
			(next)="onNext($event)"
			(addBank)="onAddBank($event)"
			(accountSelected)="accountSelected.emit($event)"
			[accountList]="accountList"
		>
		</op-plaid-account-selection-list>

		<op-plaid-offer
			#plaidLink
			[showSkip]="showSkip"
			[hidden]="accountList?.length"
			(skip)="skipPlaid($event)"
			[accountList]="accountList"
			[sectionType]="sectionType"
			[bankOnly]="bankOnly"
			[skipButtonKey]="skipButtonKey"
			[showBenefits]="showBenefits"
			[incomeVerified]="incomeVerified"
			[plaidConnectForIncomeVerified]="plaidConnectForIncomeVerified"
			[newMemberDebitCardEligibility]="newMemberDebitCardEligibility"
			[showIncentive]="showIncentive"
		>
		</op-plaid-offer>
	</div>
</div>
