import { Component, OnDestroy, OnInit } from '@angular/core';
import {
	AbstractControl,
	AbstractControlOptions,
	FormArray,
	FormBuilder,
	FormGroup,
	ValidationErrors,
	ValidatorFn,
	Validators
} from '@angular/forms';
import { iif, of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { ApplicantStepCompleteEnum } from 'src/app/core/services/loan-application/applicant/applicant.model';
import { DisbursementUtils } from 'src/app/core/services/loan-application/disbursement/disbursement-utils';
import { FundingInfoUtils } from 'src/app/core/services/loan-application/funding-info/funding-info-utils';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { ProductOfferDetailsUtils } from 'src/app/core/services/loan-application/product-offer/product-offer-details/product-offer-details-utils';
import { MetadataEnum } from 'src/app/core/services/metadata/metadata.model';
import { MetadataService } from 'src/app/core/services/metadata/metadata.service';
import { IMetadata } from 'src/app/core/services/mobile-api';
import { IReference, IReferenceResult } from 'src/app/core/services/mobile-api/mobile-api.model';
import { MobileApiService } from 'src/app/core/services/mobile-api/mobile-api.service';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { PhoneUtils } from 'src/app/core/utils/phone-utils';
import {
	firstNameValidator,
	lastNameValidator,
	maternalNameValidator,
	middleNameValidator,
	phoneValidator
} from 'src/app/shared/validators/form-validators';

@Component({
	selector: 'op-references',
	templateUrl: './references.component.html',
	styleUrls: ['./references.component.scss']
})
export class ReferencesComponent implements OnInit, OnDestroy {
	constructor(
		private formBuilder: FormBuilder,
		private metadataService: MetadataService,
		private mobileService: MobileApiService,
		private loanAppService: LoanApplicationService,
		private routingService: RoutingService,
		private dialogService: DialogService
	) {
		this.createForm(formBuilder);
	}

	formGroup: FormGroup;

	relationshipTypeList: IMetadata[];
	suffixList: IMetadata[];
	toggleLanguageOptions: IMetadata[];
	private subscription = new Subscription();

	referencesRequired: number;
	editing: boolean;

	ngOnInit(): void {
		const excludeRelationshipTypeKeys = [1509, 1511, 1516];

		const relationshipSub = this.metadataService.select(MetadataEnum.Relationship).subscribe({
			next: (relationshipTypes) =>
				(this.relationshipTypeList = relationshipTypes?.filter(
					(relationshipType) => !excludeRelationshipTypeKeys.includes(Number(relationshipType?.key))
				))
		});
		this.subscription.add(relationshipSub);

		const suffixSub = this.metadataService.select(MetadataEnum.PersonalNameSuffix).subscribe({
			next: (value) => (this.suffixList = value)
		});
		this.subscription.add(suffixSub);

		const langSub = this.metadataService.select(MetadataEnum.Language).subscribe({
			next: (value) => (this.toggleLanguageOptions = value)
		});
		this.subscription.add(langSub);

		const loanAppSub = this.loanAppService.loanApplication$.subscribe({
			next: (rsp) => {
				this.referencesRequired = this.loanAppService.getCurrentApplicant().referencesRequired;
			}
		});
		this.subscription.add(loanAppSub);

		this.mobileService.getReferences(this.loanAppService.loanApplicationId).subscribe({
			next: (response) => {
				const hasExistingReferences = response.length > 0;
				if (hasExistingReferences) {
					response.forEach((item) => this.increaseReference(item));
				} else {
					this.addReference();
				}
			}
		});

		this.formGroup.valueChanges.subscribe({
			next: (rsp) => {
				this.editing = rsp.references.some((ref) => ref.expanded);
			}
		});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	createForm(fb: FormBuilder): void {
		this.formGroup = fb.group(
			{
				references: new FormArray([])
			},
			{
				validator: [this.referencesRequiredCount()]
			} as AbstractControlOptions
		);
	}

	getSavedReferenceCount(): number {
		return this.referenceList?.controls.reduce((acc, curr) => (curr.value.id ? acc + 1 : acc), 0) || 0;
	}

	referencesRequiredCount(): ValidatorFn {
		return (formGroup: AbstractControl): ValidationErrors | null => {
			if (formGroup.errors && !formGroup.errors.requiredCount) {
				return;
			}

			if (this.getSavedReferenceCount() < this.referencesRequired) {
				formGroup.get('references').setErrors({ requiredCount: true });
			} else {
				formGroup.get('references').setErrors(null);
			}
		};
	}

	get formControls(): any {
		return this.formGroup?.controls;
	}
	get referenceList(): FormArray {
		return this.formControls?.references as FormArray;
	}

	createReference(reference: Partial<IReference>, expanded = false): FormGroup {
		const {
			id,
			firstName,
			middleName,
			lastName,
			maternalName,
			position,
			suffix,
			phoneNumber,
			preferredLanguage,
			relationship
		} = reference || {};

		return this.formBuilder.group({
			id: [id],
			firstName: [firstName, [Validators.required, firstNameValidator()]],
			middleName: [middleName, [middleNameValidator()]],
			lastName: [lastName, [Validators.required, lastNameValidator()]],
			maternalName: [maternalName, [maternalNameValidator()]],
			suffix: [suffix],
			phoneNumber: [phoneNumber, [Validators.required, phoneValidator()]],
			preferredLanguage: [preferredLanguage, Validators.required],
			relationship: [relationship, Validators.required],
			expanded: [expanded]
		});
	}

	increaseReference(reference: Partial<IReferenceResult>, expanded = false): void {
		this.referenceList.push(this.createReference(reference, expanded));
	}

	addReference(): void {
		this.increaseReference({}, true);
	}

	hasReferences(): boolean {
		return this.referenceList.controls.some((ref) => Boolean(ref.value.id));
	}

	panelOpened(ref: AbstractControl): void {
		ref.get('expanded').setValue(true);
	}

	panelClosed(ref: AbstractControl): void {
		ref.get('expanded').setValue(false);
	}

	getExpandedReference(): AbstractControl {
		return this.referenceList.controls.find((ref) => ref.value.expanded);
	}

	saveReference(/*rawReference: AbstractControl*/): void {
		const reference = this.getExpandedReference();
		const referenceValue = reference.value;

		const data = {
			firstName: referenceValue.firstName,
			middleName: referenceValue.middleName,
			lastName: referenceValue.lastName,
			maternalName: referenceValue.maternalName,
			suffix: referenceValue.suffix,
			phoneNumber: PhoneUtils.formatPhoneNumber(referenceValue.phoneNumber),
			position: this.referenceList?.length,
			preferredLanguage: referenceValue.preferredLanguage,
			relationship: referenceValue.relationship,
			id: referenceValue.id
		};

		const update$ = this.mobileService.updateReference(data, this.loanAppService.loanApplicationId);
		const set$ = this.mobileService.setReference(data, this.loanAppService.loanApplicationId);

		of(Boolean(referenceValue.id))
			.pipe(switchMap((hasId) => iif(() => hasId, update$, set$)))
			.subscribe({
				next: (rsp) => {
					reference.get('id').setValue(rsp.id);
					reference.get('expanded').setValue(false);
				},
				error: (err) => {
					this.dialogService.openErrorDialog(err).subscribe();
				}
			});
	}

	// TODO: Implement this method once the backend API has added this functionality
	deleteReference(event: any): void {
		const { reference, index } = event;
		const reverenceValue = reference.value;

		if (reverenceValue.id) {
			this.mobileService.deleteReference(reverenceValue.id, this.loanAppService.loanApplicationId).subscribe();
		}

		if (reference.parent.length === 1) {
			this.referenceList.clear();
			this.increaseReference({}, true);
		} else {
			reference.parent.removeAt(index);
		}
	}

	onSubmit(post: any): void {
		this.loanAppService.updateLoanApplication().subscribe({
			next: (loanApp) => {
				if (this.loanAppService.isIncomeVerificationEligible()) {
					this.routingService.route(RoutingPathsEnum.incomeVerification);
				} else if (this.canGotoReceiveFunds(loanApp)) {
					this.routingService.route(RoutingPathsEnum.receiveFunds);
				} else {
					this.routingService.route(RoutingPathsEnum.documentSubmit);
				}
			}
		});
	}

	private canGotoReceiveFunds(loanApp): boolean {
		const productOffer = ProductOfferDetailsUtils.fromLoanApp(loanApp);
		const funding = FundingInfoUtils.fromLoanApp(loanApp);
		const disbursement = DisbursementUtils.fromLoanApp(loanApp);

		const validProduct = Boolean(
			productOffer.hasMultipleValidProductOffers() && productOffer.hasPreApprovedSecuredPersonalLoan()
		);
		const validDisbursement = Boolean(disbursement.isChannelEmpty());
		const validFunding = Boolean(funding.isOver50miles() || funding.isMobileDisbursementEligible());
		const validStep = Boolean(!this.loanAppService.isStepComplete(ApplicantStepCompleteEnum.disbursementInfo));
		return validProduct || validDisbursement || (validFunding && validStep);
	}
}
