import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { EnvironmentService } from '../../environment/environment.service';
import { ApiUrlService } from '../api-url.service';
import { IConfigResult, IFeatureFlagResult } from './config-api.model';
import { SKIP_BUSY_INDICATOR } from 'src/app/core/interceptors/mobile-api-busy.interceptor';

@Injectable({
	providedIn: 'root'
})
export class ConfigApiService extends ApiUrlService {
	constructor(protected environmentService: EnvironmentService, private http: HttpClient) {
		super(environmentService);
	}

	private getConfig(params: HttpParams): Observable<IConfigResult> {
		return this.http.get<IConfigResult>(`${this.API_MOBILE_SERVICEABLE_URL}/config`, { params });
	}

	private getConfigByName(name: string): Observable<IConfigResult> {
		const params = new HttpParams().set('name', name);
		return this.getConfig(params);
	}

	configSoftPull(): Observable<IConfigResult> {
		return this.getConfigByName('SOFT_PULL');
	}

	configAsyncDocUploadEnabled(): Observable<IConfigResult> {
		return this.getConfigByName('ASYNC_DOC_UPLOAD_ENABLED');
	}

	configOnlineNotificationEnabled(): Observable<IConfigResult> {
		return this.getConfigByName('ONLINE_NOTIFICATION_ENABLED');
	}

	configMicroBiltEnabled(): Observable<IConfigResult> {
		return this.getConfigByName('MICROBILT_ENABLED');
	}

	configOnlineNotificationNewApplicantEnabled(): Observable<IConfigResult> {
		return this.getConfigByName('ONLINE_NOTIFICATION_NEW_APPLICANT_ENABLED');
	}

	configMobileUpgradeEnabledState(state: string): Observable<IConfigResult> {
		let params = new HttpParams();
		params = params.set('name', 'MOBILE_UPGRADE_ENABLED_STATES');
		params = params.set('value', state);
		return this.getConfig(params);
	}

	configMultiOfferEnabledStates(): Observable<IConfigResult> {
		return this.getConfigByName('MULTI_OFFER_ID_VERIFICATION_ENABLED_STATES');
	}

	configDocumentDeleteEnabled(): Observable<IConfigResult> {
		return this.getConfigByName('DOCUMENT_DELETE_ENABLED');
	}

	configFastTrackReturnEnabled(): Observable<IConfigResult> {
		return this.getConfigByName('FAST_TRACK_RETURNING_ENABLED');
	}

	configOverrideDisbursement(partnerId: number, state: string): Observable<IConfigResult> {
		const params = new HttpParams()
			.set('name', 'eligible.partner.channel.partners.enable')
			.set('partnerId', partnerId)
			.set('state', state);
		return this.getConfig(params);
	}

	configTermsTable(): Observable<IConfigResult> {
		return this.getConfigByName('ORIGINATION_FEE_UI_CONFIG_ENABLED');
	}

	showNewFundingDesign(loanId: number): Observable<IFeatureFlagResult> {
		return this.http.get<IFeatureFlagResult>(
			`${this.API_MOBILE_DISBURSEMENT_LOAN_APP_URL}/${loanId}/disbursement-selection`
		);
	}

	configOcrPollingFrequency(): Observable<IConfigResult> {
		return this.getConfigByName('MOBILE_OCR_POLLING_FREQUENCY');
	}

	configOcrPollingDuration(): Observable<IConfigResult> {
		return this.getConfigByName('MOBILE_OCR_POLLING_DURATION');
	}

	configIncomeImageCompression(): Observable<IConfigResult> {
		return this.getConfigByName('INCOME_IMAGE_COMPRESSION_ENABLED');
	}

	configIdImageCompression(): Observable<IConfigResult> {
		return this.getConfigByName('ID_IMAGE_COMPRESSION_ENABLED');
	}

	configPostUploadDelay(): Observable<IConfigResult> {
		return this.getConfigByName('MOBILE_SUBMIT_BUTTON_DELAY_COUNTDOWN');
	}

	configPostSubmitDelay(showBusy: boolean = true): Observable<IConfigResult> {
		const context = new HttpContext().set(SKIP_BUSY_INDICATOR, !showBusy);
		const params = new HttpParams().set('name', 'MOBILE_POST_SUBMIT_COUNTDOWN');
		return this.http.get<IConfigResult>(`${this.API_MOBILE_SERVICEABLE_URL}/config`, { params, context });
	}

	configEnablePlaidRefreshRetry(): Observable<IConfigResult> {
		return this.getConfigByName('PLAID_ASSET_REFRESH_RECONNECT_ENABLED');
	}

	configPushToDebitCheckAutoPay(): Observable<IConfigResult> {
		return this.getConfigByName('PUSH_TO_DEBIT_CHECK_AUTO_PAY_ENABLED');
	}

	configTabaPaySourceURL(): Observable<IConfigResult> {
		return this.getConfigByName('TABAPAY_IFRAME_URL');
	}

	showIdentityScreen(): Observable<IConfigResult> {
		return this.getConfigByName('prequal.identity.prior.to.convergence.enabled');
	}

	configGoogleMapsAPIKey(): Observable<IConfigResult> {
		return this.getConfigByName('GOOGLE_MAPS_API_KEY');
	}

	showHolidayReferralSection(): Observable<IConfigResult> {
		return this.getConfigByName('prequal.referral.code.enabled');
	}

	configInstantFundingExperience(): Observable<IConfigResult> {
		return this.getConfigByName('INSTANT_DISBURSEMENT_OFFER_UX_FEATURE_ENABLED');
	}

	configUPLStoreCheckEsignEnabled(): Observable<IConfigResult> {
		return this.getConfigByName('UPL_STORE_CHECK_ESIGN_ENABLED');
	}

	configAutoPaySkipConfirmEnabled(): Observable<IConfigResult> {
		return this.getConfigByName('AUTO_PAY_SKIP_CONFIRM_ENABLED');
	}

	configPlaidConnectAddressScreenOrderSwapEnabled(): Observable<IConfigResult> {
		return this.getConfigByName('PLAID_CONNECT_ADDRESS_SCREEN_ORDER_SWAP_ENABLED');
	}

	configStoreAgentReferralEnabled(): Observable<IConfigResult> {
		return this.getConfigByName('MOBILE_AGENT_REFERRAL_ENABLED');
	}

	configOcrBankStatementCollationEnabled(): Observable<IConfigResult> {
		return this.getConfigByName('MOBILE_OCR_BANK_STATEMENT_COLLATION_ENABLED');
	}

	configZeusConnectLinkEnabled(): Observable<IConfigResult> {
		return this.getConfigByName('connect.link.enabled');
	}

	configOcrIdOtherEnhancementEnabled(): Observable<IConfigResult> {
		return this.getConfigByName('MOBILE_OCR_ID_OTHER_ENHANCEMENT_ENABLED');
	}

	configOcrDocumentClassificationIncomeFeedbackEnabled(): Observable<IConfigResult> {
		return this.getConfigByName('OCR_DOCUMENT_CLASSIFICATION_INCOME_FEEDBACK_ENABLED');
	}
}
