import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnDestroy, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Event, NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { camelCase } from 'lodash';
import { Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { ApplicationStatusEnum } from 'src/app/core/services/loan-application/loan-application.model';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { RoutingHistoryService } from 'src/app/core/services/routing/routing-history.service';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';

export enum PageStepEnum {
	details,
	preApproved,
	submit,
	approved
}

interface IStepperBar {
	page: RoutingPathsEnum;
	hideBar?: boolean;
	steps: IStep[];
	status?: ApplicationStatusEnum[];
}

export interface IStep {
	step: PageStepEnum;
	active: boolean;
	checked: boolean;
	percent: number;
}

@Component({
	selector: 'op-stepper',
	templateUrl: './stepper.component.html',
	styleUrls: ['./stepper.component.scss'],
	animations: [
		trigger('openClose', [
			state('true', style({ height: '*', opacity: 1 })),
			state('false', style({ height: '0px', opacity: 0 })),
			transition('false <=> true', [animate('0.2s')])
		])
	]
})
export class StepperComponent implements OnInit, OnDestroy {
	private readonly pageList: IStepperBar[] = [
		{
			page: RoutingPathsEnum.home,
			hideBar: true,
			steps: [{ step: PageStepEnum.details, active: false, checked: false, percent: 0 }]
		},
		{
			page: RoutingPathsEnum.personalInfo,
			steps: [{ step: PageStepEnum.details, active: true, checked: false, percent: 1 }]
		},
		{
			page: RoutingPathsEnum.plaidConnect,
			steps: [{ step: PageStepEnum.details, active: true, checked: false, percent: 5 }],
			status: [ApplicationStatusEnum.started]
		},
		{
			page: RoutingPathsEnum.loanPurpose,
			steps: [{ step: PageStepEnum.details, active: true, checked: false, percent: 10 }],
			status: [ApplicationStatusEnum.started]
		},
		{
			page: RoutingPathsEnum.address,
			steps: [{ step: PageStepEnum.details, active: true, checked: false, percent: 25 }],
			status: [ApplicationStatusEnum.started]
		},
		{
			page: RoutingPathsEnum.identification,
			steps: [{ step: PageStepEnum.details, active: true, checked: false, percent: 25 }],
			status: [ApplicationStatusEnum.started]
		},
		{
			page: RoutingPathsEnum.creditAuth,
			steps: [{ step: PageStepEnum.details, active: true, checked: false, percent: 50 }],
			status: [ApplicationStatusEnum.started]
		},
		{
			page: RoutingPathsEnum.finances,
			steps: [{ step: PageStepEnum.details, active: true, checked: false, percent: 50 }],
			status: [ApplicationStatusEnum.started]
		},
		{
			page: RoutingPathsEnum.debt,
			steps: [{ step: PageStepEnum.details, active: true, checked: false, percent: 50 }],

			status: [ApplicationStatusEnum.started]
		},
		{
			page: RoutingPathsEnum.income,
			steps: [{ step: PageStepEnum.details, active: true, checked: false, percent: 75 }],
			status: [ApplicationStatusEnum.started]
		},
		{
			page: RoutingPathsEnum.summary,
			steps: [
				{ step: PageStepEnum.details, active: true, checked: true, percent: 100 },
				{ step: PageStepEnum.preApproved, active: true, checked: false, percent: 0 }
			],
			status: [ApplicationStatusEnum.started]
		},
		{
			page: RoutingPathsEnum.status,
			steps: [
				{ step: PageStepEnum.details, active: true, checked: true, percent: 100 },
				{ step: PageStepEnum.preApproved, active: true, checked: true, percent: 1 }
			],
			status: [ApplicationStatusEnum.preApproved]
		},
		{
			page: RoutingPathsEnum.receiveFunds,
			steps: [
				{ step: PageStepEnum.details, active: true, checked: true, percent: 100 },
				{ step: PageStepEnum.preApproved, active: true, checked: true, percent: 100 },
				{ step: PageStepEnum.submit, active: true, checked: false, percent: 50 }
			],
			status: [ApplicationStatusEnum.preApproved]
		},
		{
			page: RoutingPathsEnum.incomeVerification,
			steps: [
				{ step: PageStepEnum.details, active: true, checked: true, percent: 100 },
				{ step: PageStepEnum.preApproved, active: true, checked: true, percent: 100 },
				{ step: PageStepEnum.submit, active: true, checked: false, percent: 50 }
			],
			status: [ApplicationStatusEnum.preApproved]
		},
		{
			page: RoutingPathsEnum.bankVerification,
			steps: [
				{ step: PageStepEnum.details, active: true, checked: true, percent: 100 },
				{ step: PageStepEnum.preApproved, active: true, checked: true, percent: 100 },
				{ step: PageStepEnum.submit, active: true, checked: false, percent: 50 }
			],
			status: [ApplicationStatusEnum.preApproved, ApplicationStatusEnum.prequalAccepted]
		},
		{
			page: RoutingPathsEnum.fundingOptions,
			steps: [
				{ step: PageStepEnum.details, active: true, checked: true, percent: 100 },
				{ step: PageStepEnum.preApproved, active: true, checked: true, percent: 100 },
				{ step: PageStepEnum.submit, active: true, checked: false, percent: 50 }
			],
			status: [ApplicationStatusEnum.preApproved, ApplicationStatusEnum.prequalAccepted]
		},
		{
			page: RoutingPathsEnum.funds,
			steps: [
				{ step: PageStepEnum.details, active: true, checked: true, percent: 100 },
				{ step: PageStepEnum.preApproved, active: true, checked: true, percent: 100 },
				{ step: PageStepEnum.submit, active: true, checked: false, percent: 50 }
			],
			status: [ApplicationStatusEnum.preApproved]
		},
		{
			page: RoutingPathsEnum.autoVerifyIncome,
			steps: [
				{ step: PageStepEnum.details, active: true, checked: true, percent: 100 },
				{ step: PageStepEnum.preApproved, active: true, checked: true, percent: 100 },
				{ step: PageStepEnum.submit, active: true, checked: false, percent: 99 }
			],
			status: [ApplicationStatusEnum.preApproved]
		},
		{
			page: RoutingPathsEnum.verifyIncome,
			steps: [
				{ step: PageStepEnum.details, active: true, checked: true, percent: 100 },
				{ step: PageStepEnum.preApproved, active: true, checked: true, percent: 100 },
				{ step: PageStepEnum.submit, active: true, checked: false, percent: 99 }
			],
			status: [ApplicationStatusEnum.preApproved]
		},
		{
			page: RoutingPathsEnum.documentSubmit,
			steps: [
				{ step: PageStepEnum.details, active: true, checked: true, percent: 100 },
				{ step: PageStepEnum.preApproved, active: true, checked: true, percent: 100 },
				{ step: PageStepEnum.submit, active: true, checked: false, percent: 99 }
			],
			status: [ApplicationStatusEnum.preApproved]
		},
		{
			page: RoutingPathsEnum.status,
			steps: [
				{ step: PageStepEnum.details, active: true, checked: true, percent: 100 },
				{ step: PageStepEnum.preApproved, active: true, checked: true, percent: 100 },
				{ step: PageStepEnum.submit, active: true, checked: true, percent: 100 },
				{ step: PageStepEnum.approved, active: true, checked: false, percent: 0 }
			],
			status: [ApplicationStatusEnum.submitted]
		},
		{
			page: RoutingPathsEnum.status,
			hideBar: true,
			steps: [
				{ step: PageStepEnum.details, active: true, checked: true, percent: 100 },
				{ step: PageStepEnum.preApproved, active: true, checked: true, percent: 100 },
				{ step: PageStepEnum.submit, active: true, checked: true, percent: 100 },
				{ step: PageStepEnum.approved, active: true, checked: false, percent: 0 }
			],
			status: [ApplicationStatusEnum.referenceCheck]
		},
		{
			page: RoutingPathsEnum.status,
			hideBar: true,
			steps: [
				{ step: PageStepEnum.details, active: true, checked: true, percent: 100 },
				{ step: PageStepEnum.preApproved, active: true, checked: true, percent: 100 },
				{ step: PageStepEnum.submit, active: true, checked: true, percent: 100 },
				{ step: PageStepEnum.approved, active: true, checked: false, percent: 0 }
			],
			status: [ApplicationStatusEnum.titleReview]
		},
		{
			page: RoutingPathsEnum.status,
			hideBar: true,
			steps: [
				{ step: PageStepEnum.details, active: true, checked: true, percent: 100 },
				{ step: PageStepEnum.preApproved, active: true, checked: true, percent: 100 },
				{ step: PageStepEnum.submit, active: true, checked: true, percent: 100 },
				{ step: PageStepEnum.approved, active: true, checked: false, percent: 0 }
			],
			status: [ApplicationStatusEnum.referencesVerified]
		},
		{
			page: RoutingPathsEnum.status,
			steps: [
				{ step: PageStepEnum.details, active: true, checked: true, percent: 100 },
				{ step: PageStepEnum.preApproved, active: true, checked: true, percent: 100 },
				{ step: PageStepEnum.submit, active: true, checked: true, percent: 100 },
				{ step: PageStepEnum.approved, active: true, checked: true, percent: 100 }
			],
			status: [ApplicationStatusEnum.approved]
		},
		{
			page: RoutingPathsEnum.offerStatus,
			steps: [
				{ step: PageStepEnum.details, active: true, checked: true, percent: 100 },
				{ step: PageStepEnum.preApproved, active: true, checked: true, percent: 100 },
				{ step: PageStepEnum.submit, active: true, checked: true, percent: 100 },
				{ step: PageStepEnum.approved, active: true, checked: true, percent: 100 }
			],
			status: [ApplicationStatusEnum.approved]
		},
		{
			page: RoutingPathsEnum.offerStatus,
			steps: [
				{ step: PageStepEnum.details, active: true, checked: true, percent: 100 },
				{ step: PageStepEnum.preApproved, active: true, checked: true, percent: 1 }
			],
			status: [ApplicationStatusEnum.preApproved]
		},
		{
			page: RoutingPathsEnum.offerStatus,
			steps: [{ step: PageStepEnum.details, active: true, checked: true, percent: 100 }],
			status: [ApplicationStatusEnum.securedOffered]
		},
		{
			page: RoutingPathsEnum.offerStatus,
			steps: [{ step: PageStepEnum.details, active: true, checked: true, percent: 100 }],
			status: [ApplicationStatusEnum.securedAccepted]
		},
		{
			page: RoutingPathsEnum.offerStatus,
			steps: [{ step: PageStepEnum.details, active: true, checked: true, percent: 100 }],
			status: [ApplicationStatusEnum.securedDeclined]
		},
		{
			page: RoutingPathsEnum.vehicleEligibility,
			steps: [
				{ step: PageStepEnum.details, active: true, checked: true, percent: 100 },
				{ step: PageStepEnum.preApproved, active: true, checked: true, percent: 1 }
			],
			status: [ApplicationStatusEnum.preApproved]
		}
	];

	@Input()
	bar: boolean = false;

	constructor(
		iconRegistry: MatIconRegistry,
		sanitizer: DomSanitizer,
		private loanAppService: LoanApplicationService,
		private router: Router,
		private routingHistoryService: RoutingHistoryService
	) {
		iconRegistry.addSvgIcon(
			'stepper-btn',
			sanitizer.bypassSecurityTrustResourceUrl('/assets/images/stepper-bar-btn.svg')
		);
	}

	@ViewChild('toggleOpen') toggleOpen: ElementRef;
	@ViewChild('toggleClose') toggleClose: ElementRef;

	private subscription = new Subscription();
	collapsed = true;

	barState: IStepperBar;

	details: IStep;
	preApproved: IStep;
	submit: IStep;
	approved: IStep;

	ngOnInit(): void {
		const routingSub = this.router.events
			.pipe(
				filter((event: Event) => event instanceof NavigationEnd),
				tap(() => (this.collapsed = this.bar)),
				map((r: RoutesRecognized) => new URL(r.urlAfterRedirects, window.location.origin).pathname),
				map((path: string) => RoutingPathsEnum[camelCase(path.substring(1))]),
				map((page: RoutingPathsEnum) => this.getStepState(page))
			)
			.subscribe({
				next: (stepperState: IStepperBar) => {
					this.barState = stepperState;
					this.updateStates(stepperState);
				}
			});
		this.subscription.add(routingSub);

		const loanSub = this.loanAppService.loanApplication$.pipe(map((loanApp) => loanApp?.applicationStatus)).subscribe({
			next: () => {
				this.barState = this.getStepState(this.routingHistoryService.getCurrentRoute());
				this.updateStates(this.barState);
			}
		});
		this.subscription.add(loanSub);

		if (!this.bar) {
			this.collapsed = this.bar;
			this.barState = this.getStepState(this.routingHistoryService.getCurrentRoute());
			this.updateStates(this.barState);
		}
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	private getStepState(page: RoutingPathsEnum): IStepperBar {
		const stepState = this.pageList.find((item) => {
			if (this.loanAppService.loanApplicationId) {
				return item.page === page && item.status && item.status.includes(this.loanAppService.applicationStatus);
			} else {
				return item.page === page;
			}
		});

		return this.bar && !stepState?.hideBar ? stepState : !this.bar ? stepState : null;
	}

	private getStep(bar: IStepperBar, page: PageStepEnum): IStep {
		return bar.steps.find((item) => item.step === page);
	}

	private updateStates(bar: IStepperBar): void {
		if (bar) {
			this.details = this.getStep(bar, PageStepEnum.details);
			this.preApproved = this.getStep(bar, PageStepEnum.preApproved);
			this.submit = this.getStep(bar, PageStepEnum.submit);
			this.approved = this.getStep(bar, PageStepEnum.approved);
		}
	}

	@HostListener('keydown.enter', ['$event'])
	@HostListener('keydown.space', ['$event'])
	toggle(event: any): void {
		this.collapsed = !this.collapsed;
		setTimeout(() => {
			this.collapsed ? this.toggleOpen.nativeElement.focus() : this.toggleClose.nativeElement.focus();
		}, 25);
		event?.preventDefault();
	}
}
