import { Component, OnDestroy, OnInit } from '@angular/core';
import {
	AbstractControl,
	FormBuilder,
	FormControl,
	FormGroup,
	ValidationErrors,
	ValidatorFn,
	Validators
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { isEmpty, uniq, upperFirst } from 'lodash';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, concatMap, filter, map, share, switchMap, take, tap } from 'rxjs/operators';
import { DateUtilsService } from 'src/app/core/services/date-utils/date-utils.service';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { EnvironmentService } from 'src/app/core/services/environment/environment.service';
import { LandingService } from 'src/app/core/services/landing/landing.service';
import { LanguageEnum, LanguageService } from 'src/app/core/services/language/language.service';
import { IApplicant } from 'src/app/core/services/loan-application/applicant/applicant.model';
import { ApplicationStatusEnum, ILoanApplication } from 'src/app/core/services/loan-application/loan-application.model';
import { MetadataEnum } from 'src/app/core/services/metadata/metadata.model';
import { MetadataService } from 'src/app/core/services/metadata/metadata.service';
import { IMetadata } from 'src/app/core/services/mobile-api';
import {
	ApplicationFlowEnum,
	ILeadsByNameAddress,
	INewApplication,
	INewApplicationResult,
	IOfferCodeResult,
	IPartnerLead,
	ISetPhone,
	ISetPhoneResult
} from 'src/app/core/services/mobile-api/mobile-api.model';
import { NeuroIdService } from 'src/app/core/services/neuro-id/neuro-id.service';
import { IssuingPartnerService } from 'src/app/core/services/partner/issuing-partner.service';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import SingleSignOnService from 'src/app/core/services/single-sign-on/single-sign-on.service';
import { SessionStorageService, STORAGE_KEYS } from 'src/app/core/services/storage/session-storage.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';
import { IUser } from 'src/app/core/services/token/token.service';
import { OrganizationUtils } from 'src/app/core/utils/organization-utils';
import { PhoneTypeEnum, PhoneUtils } from 'src/app/core/utils/phone-utils';
import {
	MessageDialogComponent,
	IMessageDialogData
} from 'src/app/shared/components/dialogs/message-dialog/message-dialog.component';
import { FOUND_ACTIVE_LOAN, USER_INFORMATION_STR } from 'src/app/shared/constants/common-const';
import {
	EN_ESIGN_LINK,
	EN_PRIVACY_LINK,
	ES_ESIGN_LINK,
	ES_PRIVACY_LINK
} from 'src/app/shared/constants/language-const';
import {
	emailValidator,
	firstNameValidator,
	lastNameValidator,
	maternalNameValidator,
	middleNameValidator,
	phoneValidator,
	zipCodeValidator
} from 'src/app/shared/validators/form-validators';

import { LoanApplicationService } from '../../core/services/loan-application/loan-application.service';
import { MobileApiService } from '../../core/services/mobile-api/mobile-api.service';
import { OriginationPartnerService } from '../../core/services/partner/origination-partner.service';
import { PartnerLeadService } from '../../core/services/partner/partner-lead.service';
import { STATE_CA } from '../../shared/constants/states-const';
import { PersonalInfoService } from './personal-info.service';

declare let IGLOO: any;

export interface ICKReferral {
	zipCode: string;
	partnerReferralPartnerSource: string;
	partnerReferralUniqueId: string;
	partnerReferralAmount: number;
	partnerReferralTerm: string;
	partnerReferralApr: string;
	partnerReferralIncomeBand: string;
	partnerReferralCampaign: string;
	partnerReferralHomeOwnership: string;
	partnerReferralHousingPaymentBand: string;
	partnerReferralLoanPurpose: string;
}

export interface IWUReferral {
	zipCode: string;
	assetType: string;
	flow: string;
	bannerId: string;
	customerId: string;
	emailHash: string;
	partnerReferralPartnerSource: string;
	cta?: string;
	partnerReferralFirstName?: string;
	partnerReferralLastName?: string;
	partnerReferralLanguage?: string;
	partnerReferralState?: string;
	partnerReferralCity?: string;
	partnerReferralPostalCode?: string;
	partnerReferralIpAddress?: string;
}

@Component({
	selector: 'op-personal-info',
	templateUrl: './personal-info.component.html',
	styleUrls: ['./personal-info.component.scss'],
	providers: [PersonalInfoService]
})
export class PersonalInfoComponent implements OnInit, OnDestroy {
	ftrSavedApplicant: IApplicant;
	constructor(
		private formBuilder: FormBuilder,
		private router: Router,
		private route: ActivatedRoute,
		private dialog: MatDialog,
		private mobileService: MobileApiService,
		private loanAppService: LoanApplicationService,
		private landingService: LandingService,
		private metadataService: MetadataService,
		private dateUtilService: DateUtilsService,
		private originationPartnerService: OriginationPartnerService,
		private personalInfoService: PersonalInfoService,
		private routingService: RoutingService,
		private tagDataService: TagDataService,
		private nidService: NeuroIdService,
		private translocoService: TranslocoService,
		private languageService: LanguageService,
		private dialogService: DialogService,
		private singleSignOnService: SingleSignOnService,
		private sessionStorageService: SessionStorageService,
		private environmentService: EnvironmentService,
		private partnerLeadService: PartnerLeadService,
		private issuingPartnerService: IssuingPartnerService
	) {
		this.createForm(this.formBuilder);
	}

	zipCode: string;
	formGroup: FormGroup;

	privacyLink: string;
	esignLink: string;

	private subscription: Subscription;

	selectedValue: string[];

	toggleLanguageOptions$: Observable<IMetadata[]>;
	phoneType$: Observable<IMetadata[]>;
	togglePhoneOptions$: Observable<IMetadata[]>;
	nameSuffixOptions$: Observable<IMetadata[]>;

	toggleDateRangeOptions$: Observable<IMetadata[]>;

	loanAppId: number;

	returnToSummary: string;
	showTitle: boolean;
	originationCode: string;
	isCKReferral: boolean;
	isWUReferral: boolean;
	showOfferCodeInfo: boolean;
	isReferralCodePresent: boolean;
	returnToFTR: string;
	currentPhoneNumber: string;
	applicationFlow: string;
	applicationFlowAfterUpdate: string;
	leadPartnerSource: string;
	isNameReadOnly: boolean = false;
	consentRequired: boolean = false;
	isMetaOrganization: boolean;

	ngOnInit(): void {
		this.zipCode = this.route.snapshot.queryParams?.zipCode || this.landingService.getZipCode();
		this.returnToSummary = this.route.snapshot.queryParams?.returnToSummary;
		this.returnToFTR = this.route.snapshot.queryParams?.returnToFTR;
		this.showTitle = this.personalInfoService.referringUrl === '/home';
		this.originationCode = this.originationPartnerService.getOriginationCode();
		this.isReferralCodePresent = !isEmpty(this.route.snapshot.queryParams?.referral_code_p);

		this.subscription = this.languageService.langChanges$.subscribe({
			next: (lang) => {
				this.privacyLink = this.languageService.isSpanish ? ES_PRIVACY_LINK : EN_PRIVACY_LINK;
				this.esignLink = this.languageService.isSpanish ? ES_ESIGN_LINK : EN_ESIGN_LINK;
			}
		});
		const queryParams = this.route?.snapshot?.queryParams;
		this.isWUReferral =
			'vendor-personal-info' === this.route?.snapshot?.url?.find(Boolean)?.path &&
			this.routingService.hasValidWUReferralParams(queryParams);
		if (!this.isWUReferral) {
			this.isCKReferral =
				'vendor-personal-info' === this.route?.snapshot?.url?.find(Boolean)?.path && Boolean(queryParams?.trkcid);
		}
		this.showOfferCodeInfo = !(
			this.isCKReferral ||
			this.isWUReferral ||
			(this.returnToSummary && this.sessionStorageService.get(STORAGE_KEYS.PARTNER_REFERRAL) === 'true')
		);
		this.sessionStorageService.set(STORAGE_KEYS.PARTNER_REFERRAL, Boolean(this.isCKReferral || this.isWUReferral));

		const loanApp = this.loanAppService.getLoanApp();
		this.isMetaOrganization = OrganizationUtils.isMetaBank(this.issuingPartnerService?.lender);
		this.loanAppId = loanApp?.id;
		this.applicationFlow = loanApp?.applicationFlow;

		this.toggleDateRangeOptions$ = this.metadataService.select(MetadataEnum.DateRange);
		this.toggleLanguageOptions$ = this.metadataService.select(MetadataEnum.Language);
		this.phoneType$ = this.metadataService.select(MetadataEnum.PhoneType).pipe(share());
		this.togglePhoneOptions$ = this.phoneType$.pipe(
			map((val) =>
				PhoneUtils.filterAllowedPhones(val).filter(
					(item) => this.isHomePhoneAllowed() || item.key === PhoneTypeEnum.cell
				)
			)
		);
		if (!isEmpty(this.originationCode)) {
			this.formGroup.patchValue({ phoneType: PhoneTypeEnum.cell });
		}
		if (this.isCKReferral || this.isWUReferral) {
			this.formGroup.addControl('zipCode', new FormControl('', [Validators.required, zipCodeValidator()]));
		}

		this.nameSuffixOptions$ = this.metadataService.select(MetadataEnum.PersonalNameSuffix);

		if (this.loanAppId) {
			this.mobileService
				.getPhone(this.loanAppId)
				.pipe(map((rsp) => PhoneUtils.fromPhoneResult(rsp)))
				.subscribe({
					next: (phone) => {
						this.updateFromBe(this.loanAppService.getCurrentApplicant(), phone);
					}
				});
		} else if (this.partnerLeadService.partnerLeadId) {
			this.subscription.add(
				this.partnerLeadService
					.getPartnerLeadDetails()
					.pipe(
						tap((partnerLead: IPartnerLead) => {
							this.consentRequired =
								partnerLead.finderPartner === true &&
								partnerLead.state === STATE_CA &&
								partnerLead.consentRequired === true;

							if (this.consentRequired !== true) {
								this.zipCode = partnerLead.postalCode;
								this.leadPartnerSource = partnerLead.partnerShortName;
								this.updateFromPartnerLead(partnerLead);
							}
						}),
						switchMap((partnerLead: IPartnerLead) => {
							//zipcode lookup to get the issuing organization and update the site logo
							return this.consentRequired === true
								? of(null)
								: this.landingService.searchZipCode(partnerLead.postalCode);
						})
					)
					.subscribe({
						next: () => {
							if (this.consentRequired === true) {
								this.router.navigate([RoutingPathsEnum.caFindPartnerDisclosure]);
							}
						}
					})
			);
		} else {
			if (this.singleSignOnService.getUser()) {
				this.isNameReadOnly = true;
				this.updateFromSso(this.singleSignOnService.getUser());
			} else {
				if (this.isWUReferral) {
					const queryParams = this.route?.snapshot?.queryParams;
					const language: LanguageEnum =
						queryParams?.Language?.toUpperCase() === 'ES' ? LanguageEnum.spanish : LanguageEnum.english;
					this.languageService.language = language;

					if (queryParams?.first_name || queryParams?.last_name || queryParams?.zip) {
						this.updateFromWUQueryParams(queryParams, language);
					}
					this.tagDataService.link(
						{},
						{
							event_action: 'on_page_load',
							tealium_event: `wu_referral_origination_start`
						}
					);
				} else {
					this.updateFromSpecialOffer(this.landingService.getSpecialOfferLead());
				}
			}
		}
	}

	private isHomePhoneAllowed(): boolean {
		return (
			(!this.loanAppId && isEmpty(this.originationCode)) ||
			(this.loanAppId && !this.loanAppService.isPartnerApplication())
		);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	createForm(fb) {
		this.formGroup = fb.group({
			firstName: ['', [Validators.required, firstNameValidator(), Validators.minLength(2), Validators.maxLength(32)]],
			middleName: [null, [middleNameValidator(), Validators.minLength(1), Validators.maxLength(32)]],
			lastName: ['', [Validators.required, lastNameValidator(), Validators.minLength(2), Validators.maxLength(32)]],
			maternalName: [null, [maternalNameValidator(), Validators.minLength(2), Validators.maxLength(32)]],
			suffix: [null],
			date: [null, [Validators.required]],
			phoneType: ['', [Validators.required]],
			phoneNumber: ['', [Validators.required, phoneValidator()]],
			email: ['', [emailValidator()]],
			preferredLanguage: ['', [Validators.required]],
			ownerDateRange: [null, [Validators.required]],
			acceptTerms: [false, [this.acceptTermsValidator()]]
		});
	}

	updateFromSso(user: any): void {
		if (user) {
			this.formGroup.patchValue({
				firstName: user.firstName,
				lastName: user.lastName,
				middleName: user.middleName,
				maternalName: user.maternalName,
				email: user.emailAddress,
				preferredLanguage: user.preferredLanguage
			});
		}
	}

	updateFromPartnerLead(partnerLead: IPartnerLead): void {
		this.formGroup.patchValue({
			firstName: partnerLead.firstName,
			lastName: partnerLead.lastName,
			phoneNumber: partnerLead.phoneNumber,
			phoneType: partnerLead.phoneType,
			middleName: partnerLead.middleName,
			maternalName: partnerLead.maternalName,
			suffix: partnerLead.suffix,
			preferredLanguage: partnerLead.preferredLanguage,
			email: partnerLead.email,
			date: this.dateUtilService.parseISODate(partnerLead.dateOfBirth)
		});
	}

	updateFromSpecialOffer(lead: ILeadsByNameAddress): void {
		if (lead) {
			this.formGroup.patchValue({
				firstName: lead.firstName,
				lastName: lead.lastName
			});
		}
	}

	updateFromWUQueryParams(queryParams, language: LanguageEnum): void {
		const firstName =
			queryParams?.first_name && queryParams?.first_name?.toLowerCase() !== 'null' ? queryParams?.first_name : null;
		const lastName =
			queryParams?.last_name && queryParams?.last_name?.toLowerCase() !== 'null' ? queryParams?.last_name : null;

		this.formGroup.patchValue({
			firstName: firstName,
			lastName: lastName,
			zipCode: queryParams?.zip,
			preferredLanguage: language.toUpperCase()
		});
	}

	updateFromBe(applicant: IApplicant, phones: PhoneUtils): void {
		this.formGroup.patchValue({
			firstName: applicant.firstName,
			middleName: applicant.middleName,
			lastName: applicant.lastName,
			maternalName: applicant.maternalName,
			suffix: applicant.suffix,
			date: this.dateUtilService.parseISODate(applicant.dateOfBirth),
			phoneType: phones.getPhoneType(),
			phoneNumber: phones.getPhoneNumber(),
			email: applicant.emailAddress,
			preferredLanguage: applicant.preferredLanguage,
			ownerDateRange: phones.getPhoneOwnerDateRange() || null,
			acceptTerms: false
		});

		if (this.isCKReferral || this.isWUReferral) {
			this.formGroup.patchValue({ zipCode: applicant?.homePostalCode });
		}

		if (this.returnToFTR) {
			this.ftrSavedApplicant = applicant;
			this.formGroup.get('date').disable();
			this.formGroup.get('maternalName').disable();
			this.formGroup.get('lastName').disable();
			this.formGroup.get('middleName').disable();
			this.formGroup.get('firstName').disable();
			this.formGroup.get('ownerDateRange').reset();
		}

		if (this.returnToSummary) {
			this.currentPhoneNumber = phones.getPhoneNumber();
		}
	}

	updateFromPromoCode(promoCodeInfo: IOfferCodeResult): void {
		this.formGroup.patchValue({
			firstName: promoCodeInfo.first_name,
			middleName: promoCodeInfo.middle_name,
			lastName: promoCodeInfo.last_name,
			maternalName: promoCodeInfo.maternal_name,
			email: promoCodeInfo.email_address
		});
	}

	get firstName(): FormControl {
		return this.formGroup.get('firstName') as FormControl;
	}

	acceptTermsValidator(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (control.errors && !control.errors.re) {
				return;
			}

			if (!this.loanAppId && !control.value) {
				return { required: true };
			} else {
				return null;
			}
		};
	}

	isApplicationFlowChanged(): boolean {
		return (
			this.applicationFlow === ApplicationFlowEnum.fastTrackPrequal &&
			this.applicationFlowAfterUpdate === ApplicationFlowEnum.standard
		);
	}

	navigate(route: RoutingPathsEnum, numberUpdated = false): void {
		if (this.returnToFTR) {
			this.routingService.routeForFastTrack(this.returnToFTR);
		} else if (this.returnToSummary) {
			if (numberUpdated) {
				this.routingService.route(RoutingPathsEnum.multifactor);
			} else {
				if (this.isApplicationFlowChanged()) {
					this.routingService.route(RoutingPathsEnum.creditAuth, {
						queryParams: { returnToSummary: this.returnToSummary }
					});
				} else {
					this.routingService.route(RoutingPathsEnum.summary, { fragment: this.returnToSummary });
				}
			}
		} else if (this.isCKReferral) {
			this.routingService.route(route, { queryParams: { isPartnerReferral: this.isCKReferral } });
		} else if (this.isReferralCodePresent) {
			this.routingService.route(route, { queryParamsHandling: 'preserve' });
		} else {
			this.routingService.route(route);
		}
	}

	submitPhoneInformation(): Observable<ISetPhoneResult> {
		const loanApp = this.loanAppService.getLoanApp();
		const { phoneNumber, phoneType, ownerDateRange } = this.formGroup.value;
		const formattedPhoneNumber = PhoneUtils.formatPhoneNumber(phoneNumber);
		const phone: ISetPhone = {
			phones: [
				{
					phoneNumber: formattedPhoneNumber,
					phoneType,
					ownerDateRange
				}
			],
			smsAuthorization: phoneType === PhoneTypeEnum.cell
		};
		return this.mobileService.setPhone(phone, loanApp.id);
	}

	confirmFullName(fullName: string): Observable<any> {
		const data = {
			title: this.translocoService.translate('PERSONAL_INFO.confirmIdentity'),
			message: fullName,
			confirmText: this.translocoService.translate('GLOBAL.yes'),
			cancelText: this.translocoService.translate('PERSONAL_INFO.goBackAndEdit')
		};
		return this.dialogService.openMessageDialog(
			data,
			() => of(true),
			() => of(false)
		);
	}

	namesNotUnique(fullName): boolean {
		const names = fullName.map((n) => n.toLocaleLowerCase().trim());
		return uniq(names).length !== names.length;
	}

	onSubmit(post: any): void {
		let personalInfo = Object.assign({}, post);

		if (this.returnToFTR) {
			personalInfo.date = this.dateUtilService.parseISODate(this.ftrSavedApplicant.dateOfBirth);
			personalInfo.firstName = this.ftrSavedApplicant.firstName;
			personalInfo.middleName = this.ftrSavedApplicant.middleName;
			personalInfo.lastName = this.ftrSavedApplicant.lastName;
			personalInfo.maternalName = this.ftrSavedApplicant.maternalName;
		}
		const fullName = [
			`${personalInfo.firstName}`,
			`${personalInfo.middleName || ''}`,
			`${personalInfo.lastName}`,
			`${personalInfo.maternalName || ''}`
		].filter(Boolean);
		if (this.namesNotUnique(fullName)) {
			this.confirmFullName(fullName.join(' '))
				.pipe(map((confirm) => (confirm ? this.submit(personalInfo) : null)))
				.subscribe();
		} else {
			this.submit(personalInfo);
		}
	}

	submit(post: any): void {
		let { date, email, phoneNumber, ownerDateRange, acceptTerms, smsAuthorization, ...payload } = post;
		let loanAppFunnel = 'online';
		const tosAgree = acceptTerms;
		smsAuthorization = payload.phoneType === PhoneTypeEnum.cell;

		payload.dateOfBirth = this.dateUtilService.format(date, 'yyyy-MM-dd');
		payload.phoneNumber = PhoneUtils.formatPhoneNumber(phoneNumber);
		payload.zipCode = this.zipCode;
		payload.maternalName = isEmpty(payload.maternalName) ? null : payload.maternalName;
		payload.middleName = isEmpty(payload.middleName) ? null : payload.middleName;
		payload.emailAddress = isEmpty(email) ? null : email;
		payload.finderNumber = this.landingService.getPromoCodeInformation()?.finderNumber;

		// check if the refId exists in sessionStorage, if exists, pass it in the request
		const refId = this.sessionStorageService.get(STORAGE_KEYS.REF_ID);
		const partnerSource = this.landingService.getSpecialOfferInformation()?.partnerSource;
		if (partnerSource === 'DIGITAL_PRESCREEN') {
			payload.partnerReferralPartnerSource = partnerSource;
			payload.finderNumber = this.landingService.getSpecialOfferInformation().finderNumber;
			loanAppFunnel = 'credit_karma';
		}

		if (this.isCKReferral) {
			payload = Object.assign({}, payload, this.populateCKReferralPayload(this.route?.snapshot, post?.zipCode));
			loanAppFunnel = 'credit_karma';
		}

		if (this.isWUReferral) {
			payload = Object.assign({}, payload, this.populateWUReferralPayload(this.route?.snapshot, post?.zipCode));
			loanAppFunnel = 'west_union';
			this.sessionStorageService.set(STORAGE_KEYS.ADDRESS_COMP, {
				homeAddress: {
					zipCode: post?.zipCode
				},
				date: null,
				mailSameAsHome: true,
				mailAddress: null
			});
		}

		if (!isEmpty(this.originationCode)) {
			payload.originationCode = this.originationCode;
		}
		this.nidService.setNid('setVariable', 'funnel', loanAppFunnel);
		this.nidService.setNid('setVariable', 'loan_type', 'new');

		const marketingSource = this.sessionStorageService.get('tealium_marketing_source', false);
		if (marketingSource) {
			payload.marketingSource = `${marketingSource}`;
		}
		if (this.partnerLeadService.partnerLeadId) {
			payload.leadId = this.partnerLeadService.partnerLeadId;
			payload.leadPartnerSource = this.leadPartnerSource;
		}

		const loanAppId = this.loanAppService.loanApplicationId;
		if (loanAppId) {
			this.updateApplicant(payload, loanAppId);
		} else {
			const appSource = this.sessionStorageService.get(STORAGE_KEYS.APP_SOURCE);
			const user: IUser = this.singleSignOnService.getUser();

			if (appSource) {
				payload.applicationSource = appSource;
			}

			if (user?.oktaUserId) {
				payload.encryptedOktaUserId = user.oktaUserId;
			}

			const agentChannelId = this.getAgentChannelId();
			const newApplicationPayload: INewApplication = {
				...payload,
				ownerDateRange,
				smsAuthorization,
				tosAgree,
				agentChannelId
			};
			this.createNewApplicant(newApplicationPayload, refId);
		}
	}

	private getAgentChannelId(): string {
		const agentChannel = this.sessionStorageService.get(STORAGE_KEYS.AGENT_STORE_CHANNEL);
		return agentChannel ? `${agentChannel?.agentId}-${agentChannel?.channelId}` : undefined;
	}

	private updateApplicant(payload: INewApplication, loanAppId: number): void {
		this.landingService
			.updateApplicant(payload, loanAppId)
			.pipe(
				switchMap(() => this.submitPhoneInformation()),
				switchMap(() => this.loanAppService.updateLoanApplication())
			)
			.subscribe({
				next: (loanApp: ILoanApplication) => {
					this.singleSignOnService.reset();
					const phoneNumberUpdated = this.loanAppService.isGCP() && this.currentPhoneNumber !== payload.phoneNumber;
					this.applicationFlowAfterUpdate = loanApp.applicationFlow;
					this.navigate(RoutingPathsEnum.loanPurpose, phoneNumberUpdated);
				},
				error: (err) => {
					this.dialogService
						.openErrorDialog(err)
						.pipe(
							concatMap(() => this.loanAppService.updateLoanApplication()),
							filter((loanApp) => loanApp.applicationStatus === ApplicationStatusEnum.nonProcessable),
							tap(() => this.routingService.route(RoutingPathsEnum.home))
						)
						.subscribe();
				}
			});
	}

	private createNewApplicant(payload: INewApplication, refId?: string): void {
		this.landingService
			.submitNewApplication(payload, refId)
			.pipe(
				tap((rsp) => {
					/**
					 * Remove the refId once the application is created
					 */
					if (rsp.loanApplicationId) {
						this.sessionStorageService.remove(STORAGE_KEYS.REF_ID);
					}
					this.partnerLeadService.clearPartnerLead();
				}),
				switchMap((rsp) => (rsp.loanApplicationId && rsp.applicantId ? this.submitLasso(rsp) : of(rsp))),
				switchMap((rsp) => this.processNewAppResponse(rsp, payload))
			)
			.subscribe({
				error: (err) => {
					if (Array.isArray(err?.error) && err.error.some((e) => e?.id === USER_INFORMATION_STR)) {
						this.dialogService.refIdErrorDialog().subscribe({
							next: () => this.routingService.route(RoutingPathsEnum.personalInfo)
						});
					} else if (Array.isArray(err?.error) && err.error.some((e) => e?.id === FOUND_ACTIVE_LOAN)) {
						this.tagDataService.link(
							{},
							{
								event_action: 'Submit',
								tealium_event: 'found_active_loan_gcp'
							}
						);
						this.dialogService
							.openErrorDialog(err, {
								confirmText: this.translocoService.translate('PERSONAL_INFO.LogInToOnlineAccount')
							} as IMessageDialogData)
							.subscribe({
								next: (result: boolean | null) => {
									if (result === true) {
										this.gotoServiceLogin();
									}
								}
							});
					} else {
						this.dialogService.openErrorDialog(err).subscribe();
					}
				}
			});
	}

	private newAppNavigate(route): Observable<any> {
		return of(this.navigate(route));
	}

	private processNewAppResponse(rsp: INewApplicationResult, payload: any): Observable<any> {
		this.sessionStorageService.set('applicationFlow', rsp.applicationFlow);
		this.sessionStorageService.set('returning', payload);
		if (rsp.hasRecentNotApprovedApp) {
			return this.processRecentNotApproved(rsp);
		} else if (rsp.hasActiveLoan) {
			return this.processActiveLoan(rsp);
		} else if (rsp.inProgressApplication) {
			return this.processInProcessLoan(rsp);
		} else if (rsp.returningCustomer) {
			return this.processReturningCustomer(rsp, payload);
		} else if (rsp.loanApplicationId) {
			this.createTealiumEvent(this.landingService.getZipCode(), rsp, 'new_loan_app');
			return this.newAppNavigate(RoutingPathsEnum.loanPurpose);
		} else {
			return this.processFindApplicationError(rsp);
		}
	}

	processRecentNotApproved(newAppResult: INewApplicationResult): Observable<any> {
		const formatted_updatedAt = upperFirst(
			this.dateUtilService.format(this.dateUtilService.parseISODateTime(newAppResult.updatedAt), 'MMM dd, yyyy')
		);
		const formatted_newApplicationStartDate = upperFirst(
			this.dateUtilService.format(
				this.dateUtilService.parseISODateTime(newAppResult.newApplicationStartDate),
				'MMM dd, yyyy'
			)
		);
		const formatted_reapply = this.translocoService.translate('FIND_YOUR_APPLICATION.reApplyAfter', {
			date: formatted_newApplicationStartDate
		});
		const title = this.translocoService.translate('FIND_YOUR_APPLICATION.endOfFlowPromptTitle', {
			date: formatted_updatedAt
		});
		const footerMessage = this.translocoService.translate('FIND_YOUR_APPLICATION.endOfFlowFooterMsg');
		const message = formatted_reapply + '<p><p>' + footerMessage;
		const data = {
			title,
			message
		};
		const dialogRef = this.dialog.open(MessageDialogComponent, { data });
		return dialogRef.afterClosed().pipe(take(1));
	}

	private processActiveLoan(newAppResult: INewApplicationResult): Observable<any> {
		const message = `<strong>${this.translocoService.translate(
			'PERSONAL_INFO.existingCustomer'
		)} </strong><p><p><strong> ${this.translocoService.translate(
			'PERSONAL_INFO.mustPayOffCurrentLoan'
		)} </strong><p><p> ${this.translocoService.translate('PERSONAL_INFO.callUs')}`;

		const data = {
			title: `${this.translocoService.translate('PERSONAL_INFO.welcome')} ${this.formGroup.get('firstName').value}`,
			message,
			confirmText: this.translocoService.translate('PERSONAL_INFO.logIn')
		};
		return this.dialogService.openMessageDialog(
			data,
			(rsp) => of(this.gotoServiceLogin()),
			() => of()
		);
	}

	private processInProcessLoan(newAppResult: INewApplicationResult): Observable<any> {
		const data = {
			title: this.translocoService.translate('PERSONAL_INFO.loanInProgressTitle'),
			message: this.translocoService.translate('PERSONAL_INFO.loanInProgress'),
			confirmText: this.translocoService.translate('PERSONAL_INFO.continueApplication')
		};
		return this.dialogService.openMessageDialog(
			data,
			(rsp) => this.newAppNavigate(RoutingPathsEnum.multifactor),
			() => of()
		);
	}

	private processReturningCustomer(newAppResult: INewApplicationResult, payload: any): Observable<any> {
		const message = `<strong>${this.translocoService.translate(
			'PERSONAL_INFO.existingCustomer'
		)} </strong><p><p><strong> ${this.translocoService.translate(
			'PERSONAL_INFO.verifyIdentityCheckOffer'
		)} </strong><p><p> ${this.translocoService.translate('PERSONAL_INFO.callUs')}`;

		const data = {
			title: `${this.translocoService.translate('PERSONAL_INFO.welcome')} ${this.formGroup.get('firstName').value}`,
			message,
			confirmText: this.translocoService.translate('PERSONAL_INFO.verifyYourIdentity')
		};
		return this.dialogService.openMessageDialog(
			data,
			(rsp) => {
				return this.newAppNavigate(RoutingPathsEnum.multifactor);
			},
			() => of()
		);
	}

	private processFindApplicationError(newAppResult: INewApplicationResult): Observable<any> {
		return this.dialogService.openErrorDialogWithTitle(
			this.translocoService.translate('FIND_YOUR_APPLICATION.ERROR_MODAL.title'),
			this.translocoService.translate('FIND_YOUR_APPLICATION.ERROR_MODAL.message')
		);
	}

	private populateWUReferralPayload(snapshot: ActivatedRouteSnapshot, zipCode: string): IWUReferral {
		return {
			zipCode: zipCode,
			assetType: snapshot?.queryParams?.Asset_Type,
			flow: snapshot?.queryParams?.Flow,
			partnerReferralPartnerSource: snapshot?.queryParams?.Partner?.toUpperCase(),
			bannerId: snapshot?.queryParams?.Banner_ID,
			customerId: snapshot?.queryParams?.cust_id,
			emailHash: snapshot?.queryParams?.email_hash,
			cta: snapshot?.queryParams?.CTA,
			partnerReferralFirstName: snapshot?.queryParams?.first_name,
			partnerReferralLastName: snapshot?.queryParams?.last_name,
			partnerReferralLanguage: snapshot?.queryParams?.Language,
			partnerReferralState: snapshot?.queryParams?.state,
			partnerReferralCity: snapshot?.queryParams?.city,
			partnerReferralPostalCode: snapshot?.queryParams?.zip,
			partnerReferralIpAddress: snapshot?.queryParams?.ip
		};
	}

	private populateCKReferralPayload(snapshot: ActivatedRouteSnapshot, zipCode: string): ICKReferral {
		return {
			zipCode: zipCode,
			partnerReferralPartnerSource: 'CREDIT_KARMA',
			partnerReferralUniqueId: snapshot?.queryParams?.trkcid,
			partnerReferralAmount: snapshot?.queryParams?.amnt,
			partnerReferralTerm: snapshot?.queryParams?.term,
			partnerReferralApr: snapshot?.queryParams?.apr,
			partnerReferralIncomeBand: snapshot?.queryParams?.ib,
			partnerReferralCampaign: snapshot?.queryParams?.campaign,
			partnerReferralHomeOwnership: snapshot?.queryParams?.own,
			partnerReferralHousingPaymentBand: snapshot?.queryParams?.hp,
			partnerReferralLoanPurpose: snapshot?.queryParams?.lp
		};
	}

	createTealiumEvent(zipCode: string, response: INewApplicationResult, eventLabel: string): void {
		this.tagDataService.link(
			{
				applicant_phone_number: response.phoneNumber,
				email_hash: this.formGroup.get('email').value,
				finder_number_zip_code: zipCode
			},
			{
				tealium_event: 'personal_info',
				event_label: eventLabel,
				event_category: 'CONSUMER_INSTALLMENT_LOAN'
			}
		);
		if (this.isWUReferral) {
			this.tagDataService.link(
				{},
				{
					event_action: 'Submit',
					tealium_event: 'wu_referral_origination_created'
				}
			);
		}
	}

	submitLasso(app: INewApplicationResult): Observable<INewApplicationResult> {
		// TODO: not for internal devices
		const bbInfo = IGLOO.getBlackbox(); /* cspell: disable-line */
		const lasso = {
			blackBox: bbInfo.blackbox /* cspell: disable-line */,
			captureDate: new Date().toISOString(),
			applicantId: String(app.applicantId),
			loanApplicationId: String(app.loanApplicationId),
			page: this.router.url
		};
		return this.mobileService.lassoCapture(lasso).pipe(
			map(() => app),
			catchError(() => of(app))
		);
	}

	gotoServiceLogin() {
		window.open(
			`${this.environmentService.getServicingHost()}/services/${this.translocoService.getActiveLang()}`,
			'_self'
		);
	}
}
