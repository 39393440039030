import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
	AccountStatusEnum,
	AchBankAccountsService,
	IAchBankAccount
} from 'src/app/core/services/ach-bank-accounts/ach-bank-accounts.service';
import { AccountListDisplayModeEnum, SectionTypeEnum } from 'src/app/core/services/plaid-link/plaid-link.model';

import { opRequired } from '../../decorators/required.decorator';
import { PlaidOfferComponent } from './plaid-offer/plaid-offer.component';

@Component({
	selector: 'op-plaid-bank-transaction',
	templateUrl: './plaid-bank-transaction.component.html',
	styleUrls: ['./plaid-bank-transaction.component.scss']
})
export class PlaidBankTransactionComponent implements OnInit, OnDestroy {
	@ViewChild('plaidLink') plaidLink: PlaidOfferComponent;

	@Input()
	@opRequired()
	id: string;

	@Input()
	bankOnly: boolean = false;

	@Input()
	sectionType: SectionTypeEnum;

	@Input()
	accountListDisplayMode: AccountListDisplayModeEnum = AccountListDisplayModeEnum.readonly;

	@Input()
	newMemberDebitCardEligibility: boolean = false;

	@Input()
	showIncentive: boolean = true;

	accountListDisplayModeEnum = AccountListDisplayModeEnum;

	@Output() accountSelected = new EventEmitter<number>();
	@Output() plaidAccountLoaded = new EventEmitter<boolean>();
	@Output() next = new EventEmitter();
	@Output() skip = new EventEmitter();
	@Input() showSkip = true;
	@Input() skipButtonKey: string;
	@Input() plaidConnectForIncomeVerified: boolean = false;
	@Input() showBenefits: boolean = false;
	@Input() incomeVerified: boolean = false;
	@Input()
	accountListFilter: (element: IAchBankAccount, index: number, array: IAchBankAccount[]) => boolean;

	constructor(protected bankAccountService: AchBankAccountsService) {}

	private subscription = new Subscription();

	initialize = false;
	bankAccountList$: Observable<IAchBankAccount[]>;
	accountList: IAchBankAccount[] = [];

	ngOnInit() {
		this.accountListFilter = this.accountListFilter || this.defaultAccountListFilter;
		this.bankAccountList$ = this.bankAccountService.bankAccounts$;
		this.bankAccountService.refreshBankAccounts();
		const bankSub = this.bankAccountList$.pipe(filter(Boolean)).subscribe({
			next: (rsp: IAchBankAccount[]) => {
				this.accountList = rsp.filter(this.accountListFilter);
				this.initialize = true;
				this.plaidAccountLoaded.emit(true);
			}
		});
		this.subscription.add(bankSub);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	private defaultAccountListFilter(element: IAchBankAccount): boolean {
		return element.verificationStatus === AccountStatusEnum.verified;
	}

	skipPlaid(): void {
		this.skip.emit();
	}

	onAddBank(): void {
		this.plaidLink.openPlaid();
	}

	onNext(): void {
		this.next.emit();
	}
}
