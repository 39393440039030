import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';

@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: 'input,mat-checkbox,mat-radio-group'
})
export class TrackInputDirective {
	// eslint-disable-next-line @angular-eslint/no-input-rename
	@Input('trackInputValue') trackInputValue: boolean;

	constructor(private element: ElementRef, private tagDataService: TagDataService) {}

	@HostListener('focusout', ['$event.target']) onFocusOutEvent() {
		let fieldName = this.element.nativeElement.name || this.element.nativeElement.id;
		const elementValue = this.element.nativeElement.value;
		this.tagDataService.link(
			{},
			{
				event_action: Boolean(this.trackInputValue) ? elementValue : `field_complete_${fieldName}`,
				event_category: 'CONSUMER_INSTALLMENT_LOAN',
				event_label: fieldName,
				form_field: fieldName,
				tealium_event: 'field_complete'
			}
		);
	}
}
