import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { isEmpty, noop } from 'lodash';
import { compose, filter as _filter, includes, sortBy, uniqBy } from 'lodash/fp';
import { forkJoin, iif, Observable, of, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { FOUND_ACTIVE_LOAN } from 'src/app/shared/constants/common-const';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { IApplicant } from 'src/app/core/services/loan-application/applicant/applicant.model';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { MetadataEnum } from 'src/app/core/services/metadata/metadata.model';
import { MetadataService } from 'src/app/core/services/metadata/metadata.service';
import { IMetadata } from 'src/app/core/services/mobile-api';
import {
	IIdentification,
	IIdentificationResult
} from 'src/app/core/services/mobile-api/identification-api/identification-api.model';
import { IdentificationApiService } from 'src/app/core/services/mobile-api/identification-api/identification-api.service';
import { MobileApiService } from 'src/app/core/services/mobile-api/mobile-api.service';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { SessionStorageService } from 'src/app/core/services/storage/session-storage.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';
import { StatesEnum } from 'src/app/core/utils/state-utils';
import {
	integerValidator,
	itinValidator,
	maskedSsnItinValidator,
	ssnValidator
} from 'src/app/shared/validators/form-validators';

import { ShowIdentificationFieldPipe } from 'src/app/shared/pipes/identification/identification.pipe';

@Component({
	selector: 'op-identification',
	templateUrl: './identification.component.html',
	styleUrls: ['./identification.component.scss'],
	providers: [ShowIdentificationFieldPipe]
})
export class IdentificationComponent implements OnInit, OnDestroy {
	constructor(
		private formBuilder: FormBuilder,
		private route: ActivatedRoute,
		private mobileService: MobileApiService,
		private loanAppService: LoanApplicationService,
		private metadataService: MetadataService,
		private routingService: RoutingService,
		private identificationService: IdentificationApiService,
		private tagDataService: TagDataService,
		private showIdentificationField: ShowIdentificationFieldPipe,
		private dialogService: DialogService,
		private translocoService: TranslocoService,
		private sessionStorageService: SessionStorageService,
		private authService: AuthenticationService
	) {
		this.createForm(this.formBuilder);
	}

	formGroup: FormGroup;

	private readonly CONFIRM_INITIAL_TAKE_HOME_PAY = 10000;
	private subscription = new Subscription();
	private readonly storageKey = 'identificationComp';

	idTypeList$: Observable<IMetadata[]>;
	stateList$: Observable<IMetadata[]>;
	stateListRestricted$: Observable<IMetadata[]>;
	countryList$: Observable<IMetadata[]>;
	isMaskedInput: boolean;
	isSSNorITINReadonly: boolean;
	isNewJerseyState: boolean;
	// TODO: localize
	toggleSsnItinOptions: Array<IMetadata> = [
		{ key: 'ssn', text: 'IDENTIFICATION.ssn' },
		{ key: 'itin', text: 'IDENTIFICATION.itin' },
		{ key: 'none', text: 'IDENTIFICATION.dontHave' }
	];
	toggleTaxFilingQuestionOptions: Array<IMetadata> = [
		{ key: true, text: 'GLOBAL.yes' },
		{ key: false, text: 'GLOBAL.no' }
	];

	applicant: IApplicant;
	currentState: string;

	// {key: 2201, text: "US Driver's License"}
	// {key: 2203, text: "US Passport"}
	// {key: 2218, text: "Limited Use (Resident) Driver’s License"}
	// {key: 2206, text: "US State/City ID Card"}
	// {key: 2210, text: "US Dept of State Driver's License/ID"}
	// {key: 2204, text: "US Permanent Residence Card"}
	// {key: 2213, text: "US Employment Authorization Card"}
	// {key: 2215, text: "US Border Crossing Card"}
	// {key: 2216, text: "US Non-immigrant Visa"}
	// {key: 2217, text: "Foreign Passport"}
	// {key: 2202, text: "Foreign Matricula Consular"}
	// {key: 2211, text: "Foreign Driver's License"}
	// {key: 2212, text: "Foreign Identity Card"}
	// {key: 2205, text: "National Electoral Card"}
	// {key: 2207, text: "Other"}
	readonly VISIBLE_ID_TYPES = [2201, 2202, 2203, 2206, 2207, 2217, 2218];
	readonly FOREIGN_ID_TYPES = [2202, 2217];
	readonly KEY_USA = 'US';

	// STATE_BASED_IDS: [2201, 2218, 2206],
	// NON_STATE_BASED_IDS: [2203, 2204, 2206, 2210, 2213, 2214, 2215, 2216],
	// US_ID_TYPES: [2203, 2201, 2206, 2210, 2204, 2213, 2214, 2215, 2216, 2218],

	// moved to identification.pipe
	// showFieldCondition = {
	// 	showIssuingState: [2201, 2218, 2206],
	// 	showNoneOpt: [2218, 2217],  // none, ssn, itin
	// 	showTaxFilingQuestion: [2218, 2217, 2202, 2207],
	// 	showUSStatesRestrictedStates: [],
	// 	showIssuingCountry: [2218, 2217, 2202, 2207],  // issuing country?
	// 	showUSStatesRestrictedCountries: [],
	// 	showSsn: [],    // ssnOrItin === ssn
	// 	showItin: []    // ssnOrItin === itin
	// };

	returnToSummary: string;

	customSsnItinPatterns = {
		B: {
			pattern: new RegExp('[0-9*]'),
			//pattern: new RegExp('^[0-9*]*$'),
			symbol: '*'
		}
	};

	currentIdentification: IIdentificationResult;

	returnToFTR: string;
	isPrequal: boolean = false;

	ngOnInit(): void {
		this.returnToSummary = this.route.snapshot.queryParams?.returnToSummary;

		this.returnToFTR = this.route.snapshot.queryParams?.returnToFTR;

		this.idTypeList$ = this.metadataService.select(MetadataEnum.IdentificationType).pipe(
			map((val) => {
				return _filter((type: IMetadata) => includes(type.key)(this.VISIBLE_ID_TYPES))(val);
			})
		);

		this.stateListRestricted$ = this.metadataService.select(MetadataEnum.USStatesRestrictedIDs).pipe(
			map((val) => {
				return compose(sortBy('text'), uniqBy('text'))(val as any) as any[];
			})
		);

		this.stateList$ = this.metadataService.select(MetadataEnum.USState).pipe(
			map((val) => {
				return compose(sortBy('text'), uniqBy('text'))(val as any) as any[];
			})
		);

		const loanAppSub = this.loanAppService.loanApplication$
			.pipe(
				filter(Boolean),
				take(1),
				switchMap((loanApp) =>
					forkJoin({
						loanApp: of(loanApp),
						applicant: this.mobileService.getApplicant(this.loanAppService.loanApplicationId)
					})
				),
				tap(({ loanApp, applicant }) => {
					this.applicant = applicant;
					this.currentState = this.applicant.currentAddress?.state;
					this.isPrequal = this.loanAppService.isPrequal(loanApp);
				}),
				switchMap(() => this.identificationService.getIdentification(this.loanAppService.loanApplicationId))
			)
			.subscribe({
				next: (identification) => {
					this.currentIdentification = isEmpty(identification) ? null : identification;
					this.monitorFormValues();
					this.updateFromBe(this.currentIdentification);
				},
				error: () => {
					this.monitorFormValues();
				}
			});
		this.subscription.add(loanAppSub);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	onFocus(control: AbstractControl): void {
		control?.errors?.required ? control?.reset() : noop();
	}

	createForm(fb: FormBuilder): void {
		this.formGroup = fb.group({
			idType: [null, [Validators.required, this.optionalRequired()]],
			ssnOrItin: [null, [Validators.required]],
			totalHomePay: [null, [Validators.required, Validators.min(1), integerValidator()]],

			issuingState: [null],
			issuingCountry: [null],
			taxFilingQuestion: [null],
			ssnItinNumber: [null, [Validators.maxLength(9)]]
		});
	}

	private monitorFormValues(): void {
		const formSub = this.formGroup.valueChanges.subscribe({
			next: (values) => {
				this.sessionStorageService.set(this.storageKey, values);
			}
		});
		this.subscription.add(formSub);

		const formIssuingState = this.formGroup.get('issuingState').valueChanges.subscribe({
			next: (value) => {
				// set boolean value for new jersey state
				this.isNewJerseyState = value === 'NJ';
			}
		});
		this.subscription.add(formIssuingState);

		const formTaxFiling = this.formGroup.get('taxFilingQuestion').valueChanges.subscribe({
			next: (value) => {
				// set value for SSNorItin to None if tax filing status in false.
				if (this.isNewJerseyState && value === true) {
					this.formGroup.get('ssnOrItin').setValue('ssn');
				}
				if (this.isNewJerseyState && value === false) {
					this.formGroup.get('ssnOrItin').setValue('none');
				}
			}
		});
		this.subscription.add(formTaxFiling);

		const formIdType = this.formGroup.get('idType').valueChanges.subscribe({
			next: (value) => {
				// initialize the country list
				this.countryList$ = this.metadataService
					.select(MetadataEnum.Country)
					.pipe(
						map((val: IMetadata[]) =>
							this.FOREIGN_ID_TYPES.includes(value) ? val.filter((element) => element.key != this.KEY_USA) : val
						)
					);

				if (value === 2218) {
					this.formGroup.get('issuingCountry').setValue('US');
					this.formGroup.get('issuingState').reset();
				} else {
					this.formGroup.get('issuingCountry').reset();
					this.formGroup.get('issuingState').reset();
				}
			}
		});
		this.subscription.add(formIdType);

		const formSsnItin = this.formGroup
			.get('ssnItinNumber')
			.valueChanges.pipe(filter(Boolean), distinctUntilChanged())
			.subscribe({
				next: (value: string) => {
					const ssnOrItinValue = this.formGroup?.get('ssnOrItin').value;
					const isSSN = ssnOrItinValue === 'ssn' ? true : false;
					const validatorType = isSSN ? ssnValidator() : itinValidator();
					if (value) {
						if (value.includes('*')) {
							this.isMaskedInput = true;
							this.formGroup.get('ssnItinNumber').setValidators(maskedSsnItinValidator());
						} else {
							this.isMaskedInput = false;
							this.formGroup.get('ssnItinNumber').setValidators([validatorType, Validators.required]);
						}
					} else {
						this.formGroup.get('ssnItinNumber').addValidators(Validators.required);
					}
				}
			});
		this.subscription.add(formSsnItin);

		const formSsnOrItin = this.formGroup.get('ssnOrItin').valueChanges.subscribe({
			next: (value) => {
				this.clearSsnItinNumber();
			}
		});
		this.subscription.add(formSsnOrItin);
	}

	private clearValidator(): void {
		const fields = ['issuingState', 'issuingCountry', 'taxFilingQuestion'];
		if (this.formGroup) {
			fields.forEach((field) => {
				this.formGroup.get(field).clearValidators();
				this.formGroup.get(field).updateValueAndValidity();
			});
		}
	}

	private setRequiredValidator(fields: string[] = []): void {
		if (this.formGroup) {
			fields.forEach((field) => {
				this.formGroup.get(field).setValidators(Validators.required);
				this.formGroup.get(field).updateValueAndValidity();
			});
		}
	}

	private optionalRequired(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } | null => {
			const fields = control.value
				? this.showIdentificationField.getFieldsRequired(control.value, StatesEnum[this.currentState])
				: [];
			this.clearValidator();
			this.setRequiredValidator(fields);
			return null;
		};
	}

	private formatFormGroupValues(idInfo: IIdentificationResult, isLast4Itin: string): any {
		const isItin = idInfo?.last4Itin ? 'itin' : 'none';
		return {
			idType: idInfo?.type ?? null,
			ssnOrItin: idInfo?.last4Ssn ? 'ssn' : isItin,
			totalHomePay: idInfo?.initialTakeHomePay ?? null,
			issuingState: idInfo?.issuingState ?? null,
			issuingCountry: idInfo?.issuingCountry ?? null,
			taxFilingQuestion: idInfo?.reportsIncomeTax ?? null,
			ssnItinNumber: idInfo?.last4Ssn ? this.maskSsnItin(idInfo?.last4Ssn) : isLast4Itin
		};
	}

	updateFromBe(idInfo: IIdentificationResult): void {
		const isLast4Itin = idInfo?.last4Itin ? this.maskSsnItin(idInfo?.last4Itin) : '';
		const sessionData = this.sessionStorageService.get(this.storageKey);

		if (!isEmpty(idInfo)) {
			this.isMaskedInput = true;
			this.formGroup.setValue(this.formatFormGroupValues(idInfo, isLast4Itin));
			const ssnOrItinValue = this.formGroup?.get('ssnItinNumber').value;
			if (ssnOrItinValue.includes('*')) {
				this.isMaskedInput = true;
				this.updateSsnItinValidation(this.formGroup?.get('ssnOrItin').value, ssnOrItinValue);
			}
		} else if (sessionData) {
			this.formGroup.setValue(sessionData);
		}

		if (this.returnToFTR) {
			this.formGroup.patchValue({
				ssnItinNumber: idInfo?.last4Ssn ? this.maskSsnItin(idInfo?.last4Ssn) : isLast4Itin
			});
			this.formGroup.get('ssnItinNumber').disable();
			this.isSSNorITINReadonly = true;
			this.formGroup.patchValue({
				totalHomePay: null
			});
		}
	}

	updateSsnItinValidation(type: string, ssnItin: string): void {
		if (type === 'ssn') {
			this.formGroup
				?.get('ssnItinNumber')
				.setValidators([this.isMaskedInput ? maskedSsnItinValidator() : ssnValidator(), Validators.required]);
		} else if (type === 'itin') {
			this.formGroup
				?.get('ssnItinNumber')
				.setValidators([this.isMaskedInput ? maskedSsnItinValidator() : itinValidator(), Validators.required]);
		}
		this.formGroup?.get('ssnItinNumber').updateValueAndValidity();
	}

	// TODO:mask is not being applied
	maskSsnItin(ssnItin: string): string {
		return `*****${ssnItin}`;
	}

	/**
	 * Check if ssn changed before sending it.
	 *
	 * @private
	 * @param {string} ssn
	 * @return {*}  {string}
	 * @memberof IdentificationComponent
	 */
	private getSsn(ssn: string): string {
		if (ssn === this.maskSsnItin(this.currentIdentification?.last4Ssn)) {
			return null;
		}
		return ssn;
	}

	/**
	 * check if itin changed before sending it.
	 *
	 * @private
	 * @param {string} itin
	 * @return {*}  {string}
	 * @memberof IdentificationComponent
	 */
	private getItin(itin: string): string {
		if (itin === this.maskSsnItin(this.currentIdentification?.last4Itin)) {
			return null;
		}
		return itin;
	}

	private getNewIdentification(post): IIdentification {
		return {
			type: Number(post.idType),
			dateOfBirth: this.applicant?.dateOfBirth && this.applicant?.dateOfBirth?.substring(0, 10),
			initialTakeHomePay: Number(post.totalHomePay),
			issuingCountry: post.issuingCountry ? post.issuingCountry : 'US',
			issuingState: post.issuingState,
			reportsIncomeTax: post.taxFilingQuestion,
			ssn: post.ssnOrItin === 'ssn' ? this.getSsn(post.ssnItinNumber) : '',
			itin: post.ssnOrItin === 'itin' ? this.getItin(post.ssnItinNumber) : ''
		};
	}

	navigate(route: RoutingPathsEnum): void {
		if (this.returnToFTR) {
			this.routingService.routeForFastTrack(this.returnToFTR);
		} else if (this.returnToSummary) {
			this.routingService.route(RoutingPathsEnum.summary, { fragment: this.returnToSummary });
		} else {
			this.routingService.route(route);
		}
	}

	onSubmit(post: any): void {
		let identification = this.getNewIdentification(post);
		if (this.returnToFTR && post.ssnOrItin === 'ssn') {
			identification = Object.assign({}, identification, { ssn: this.currentIdentification?.ssn });
		}
		const update$ = this.identificationService.updateIdentification(
			identification,
			this.loanAppService.loanApplicationId
		);
		const set$ = this.identificationService.setIdentification(identification, this.loanAppService.loanApplicationId);

		of(Boolean(identification.initialTakeHomePay > this.CONFIRM_INITIAL_TAKE_HOME_PAY))
			.pipe(
				switchMap((confirmDlg) => (confirmDlg ? this.confirmInitialTakeHomePay() : of(true))),
				filter(Boolean),
				map(() => Boolean(this.currentIdentification)),
				switchMap((rsp) => iif(() => rsp, update$, set$))
			)
			.subscribe({
				next: () => {
					//Skip creditAuth page for Prequal application and move to Finance page
					if (this.isPrequal) {
						this.navigate(RoutingPathsEnum.finances);
					} else if (this.returnToFTR) {
						this.routingService.routeForFastTrack(this.returnToFTR);
					} else {
						this.routingService.routeFromLoanApp();
					}
					this.tagDataService.link(
						{},
						{
							tealium_event: 'identification_process_complete',
							event_action: 'submit',
							event_category: 'Form',
							event_label: 'rx_form'
						}
					);
					this.sessionStorageService.remove(this.storageKey);
				},
				error: (err) => {
					if (Array.isArray(err?.error) && err.error.some((e) => e?.id === FOUND_ACTIVE_LOAN)) {
						this.tagDataService.link(
							{},
							{
								event_action: 'Submit',
								tealium_event: 'found_active_loan_gcp'
							}
						);
						this.dialogService.openErrorDialog(err).subscribe({
							next: (result: boolean | null) => this.routingService.route(RoutingPathsEnum.status)
						});
					} else {
						this.dialogService
							.openErrorDialogWithTitle(this.translocoService.translate('DIALOG_MESSAGE.genericTitle'), err)
							.pipe(
								filter(() => Array.isArray(err?.error) && err.error.some((e) => e?.id === 'appError1')),
								switchMap(() => this.authService.signOut()),
								tap(() => this.routingService.route(RoutingPathsEnum.findYourApplication))
							)
							.subscribe();
					}
				}
			});
	}

	getSsnOrItinError(): string {
		return this.formGroup?.get('ssnOrItin')?.value === 'ssn'
			? 'IDENTIFICATION.provideValidSSN'
			: 'IDENTIFICATION.provideValidITIN';
	}

	confirmInitialTakeHomePay(): Observable<any> {
		return this.dialogService.openMessageDialog(
			{
				cancelText: this.translocoService.translate('GLOBAL.edit'),
				confirmText: this.translocoService.translate('GLOBAL.continue'),
				title: this.translocoService.translate('IDENTIFICATION.initialTakeHomePayConfirmTitle'),
				message: this.translocoService.translate('IDENTIFICATION.initialTakeHomePayConfirm')
			},
			() => of(true),
			() => of(false)
		);
	}

	clearSsnItinNumber(): void {
		this.formGroup.get('ssnItinNumber').reset();
		this.formGroup.get('ssnItinNumber').clearValidators();
		this.isMaskedInput = false;
		const ssnOrItinValue = this.formGroup?.get('ssnOrItin').value;
		if (ssnOrItinValue === 'ssn') {
			this.formGroup
				?.get('ssnItinNumber')
				.setValidators([this.isMaskedInput ? maskedSsnItinValidator() : ssnValidator(), Validators.required]);
		} else if (ssnOrItinValue === 'itin') {
			this.formGroup
				?.get('ssnItinNumber')
				.setValidators([this.isMaskedInput ? maskedSsnItinValidator() : itinValidator(), Validators.required]);
		}
		this.formGroup?.get('ssnItinNumber').updateValueAndValidity();
	}
}
