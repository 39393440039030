import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { isFunction, noop } from 'lodash';
import { Subscription } from 'rxjs/internal/Subscription';
import { SectionTypeEnum } from 'src/app/core/services/plaid-link/plaid-link.model';
import {
	BankConnectEventTypeEnum,
	BankConnectResponseStatusEnum,
	IBankConnectEvent,
	PlaidLinkService
} from 'src/app/core/services/plaid-link/plaid-link.service';
import { PlaidButtonComponent } from 'src/app/shared/components/plaid-button/plaid-button.component';
import { opRequired } from 'src/app/shared/decorators/required.decorator';

@Component({
	selector: 'op-plaid-offer',
	templateUrl: './plaid-offer.component.html',
	styleUrls: ['./plaid-offer.component.scss']
})
export class PlaidOfferComponent implements OnInit, OnDestroy {
	private _skipButtonKey = 'PLAID_CONNECT.skipForNow';

	@ViewChild('plaidButton') plaidButton: PlaidButtonComponent;

	@Output() skip = new EventEmitter();

	@Input()
	@opRequired()
	id: string;

	@Input()
	bankOnly: boolean = false;

	@Input()
	sectionType: SectionTypeEnum;

	@Input() showSkip = true;

	@Input()
	showBenefits: boolean = true;

	@Input()
	plaidConnectForIncomeVerified: boolean = false;

	@Input()
	incomeVerified: boolean = false;

	@Input()
	newMemberDebitCardEligibility: boolean = false;

	@Input()
	showIncentive: boolean = true;

	@Input()
	set skipButtonKey(key: string) {
		if (key) {
			this._skipButtonKey = key;
		}
	}

	get skipButtonKey() {
		return this._skipButtonKey;
	}

	constructor(private plaidLinkService: PlaidLinkService) {}

	private subscription = new Subscription();

	showNoAccountsError: boolean = false;
	bankConnectEvent: BankConnectEventTypeEnum = BankConnectEventTypeEnum.idle;

	ngOnInit(): void {
		const plaidSub = this.plaidLinkService.plaid$.subscribe({
			next: (rsp) => {
				this.onBankConnect(rsp);
			}
		});
		this.subscription.add(plaidSub);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	skipPlaid(): void {
		this.skip.emit();
	}

	openPlaid(): void {
		this.plaidButton.openPlaid();
	}

	onBankConnect(event: IBankConnectEvent): void {
		this.bankConnectEvent =
			event.type === BankConnectEventTypeEnum.closed ? BankConnectEventTypeEnum.waiting : event.type;
		const bankConnectEvents = {
			[BankConnectEventTypeEnum.opened]: this.bankConnectionOpenedCallback.bind(this),
			[BankConnectEventTypeEnum.complete]: this.bankConnectionCompleteCallback.bind(this),
			[BankConnectEventTypeEnum.error]: this.bankErrorCallback.bind(this)
		};
		return isFunction(bankConnectEvents[event?.type]) ? bankConnectEvents[event.type](event) : noop;
	}

	bankConnectionOpenedCallback(event: IBankConnectEvent): void {
		this.showNoAccountsError = false;
	}

	bankConnectionCompleteCallback(event: IBankConnectEvent): void {
		if (event.data.responseStatus === BankConnectResponseStatusEnum.emptyAccountList) {
			this.showNoAccountsError = true;
		} else {
			this.showNoAccountsError = false;
		}
	}

	bankErrorCallback(event: IBankConnectEvent): void {
		this.showNoAccountsError = true;
	}
}
