import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import {
	EventDataService,
	EventDataTagActionEnum,
	EventDataTagTypeEnum
} from 'src/app/core/services/event-data/event-data.service';
import { PlaidBannerService } from 'src/app/core/services/plaid-banner/plaid-banner.service';
import {
	BankConnectEventTypeEnum,
	IBankConnectEvent,
	PlaidLinkService
} from 'src/app/core/services/plaid-link/plaid-link.service';
import { RoutingHistoryService } from 'src/app/core/services/routing/routing-history.service';
import { RoutingService } from 'src/app/core/services/routing/routing.service';
import { SessionStorageService } from 'src/app/core/services/storage/session-storage.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';

@Component({
	selector: 'op-plaid-connect',
	templateUrl: './plaid-connect.component.html',
	styleUrls: ['./plaid-connect.component.scss']
})
export class PlaidConnectComponent implements OnInit, OnDestroy {
	private readonly storageKeyConnected = 'plaidConnected';
	private subscription = new Subscription();
	plaidConnected = false;

	constructor(
		private plaidBannerService: PlaidBannerService,
		private routingService: RoutingService,
		private eventDataService: EventDataService,
		private tagDataService: TagDataService,
		private plaidLinkService: PlaidLinkService,
		private sessionStorageService: SessionStorageService,
		private routingHistoryService: RoutingHistoryService
	) {
		this.plaidConnected = this.sessionStorageService.get(this.storageKeyConnected);
	}

	ngOnInit(): void {
		const plaidSub = this.plaidLinkService.plaid$.subscribe({
			next: (event: IBankConnectEvent) => {
				if (!this.plaidConnected) {
					this.plaidConnected = event.type === BankConnectEventTypeEnum.complete;
					this.sessionStorageService.set(this.storageKeyConnected, this.plaidConnected);
				}
			}
		});
		this.subscription.add(plaidSub);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
		this.sessionStorageService.remove(this.storageKeyConnected);
	}

	skipPlaid(): void {
		!this.plaidConnected ? this.plaidBannerService.show() : this.plaidBannerService.hide();
		this.routingService.routeFromLoanApp();
		this.plaidLinkService.setPlaidConnectBannerValue(false);
		this.logToLoanApplication();
		this.sendTagEvent();
	}

	private logToLoanApplication(): void {
		const plaidEvent = {
			eventType: EventDataTagTypeEnum.skipEventType,
			value1: EventDataTagActionEnum.skipEventAction + '_' + this.routingHistoryService.getCurrentRoute()
		};
		this.eventDataService.logEventDataToLoanApplication(plaidEvent);
	}

	private sendTagEvent(): void {
		const tagEvent = {
			tealium_event: EventDataTagTypeEnum.skipEventType,
			event_action: EventDataTagActionEnum.skipEventAction + '_' + this.routingHistoryService.getCurrentRoute()
		};
		this.tagDataService.link({}, tagEvent);
	}

	onNext(): void {
		this.plaidBannerService.hide();
		this.routingService.routeFromLoanApp();
		this.plaidLinkService.setPlaidConnectBannerValue(false);
	}
}
