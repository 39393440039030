import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NewRelicInteractionService } from 'src/app/core/services/new-relic/new-relic-interaction.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';

@Directive({
	selector:
		'button[opTagClick],a[opTagClick],op-expansion-panel[opTagClick],mat-expansion-panel[opTagClick], mat-radio-button[opTagClick],op-slide-toggle[opTagClick],op-document-section[opTagClick],div[opTagClick], section[opTagClick]'
})
export class TagClickDirective {
	// eslint-disable-next-line @angular-eslint/no-input-rename
	@Input('opTagClick') tagClicked: any;

	// eslint-disable-next-line @angular-eslint/no-input-rename
	@Input('opTagEventLabel') tagEventLabel: any;

	constructor(
		private tagDataService: TagDataService,
		private el: ElementRef,
		private newRelicInteractionService: NewRelicInteractionService
	) {}

	@HostListener('click') onClickEvent() {
		const tagName = this.getTagName();
		if (this.tagClicked) {
			let eventLabel = this.tagEventLabel ? this.tagEventLabel : 'rx_form';
			this.tagDataService.link(
				{},
				{
					tealium_event: this.tagClicked,
					event_label: eventLabel,
					event_category: 'Form',
					event_action: 'submit'
				}
			);
			this.newRelicInteractionService.saveInteraction(this.tagClicked);
		}
	}

	getTagName(): string {
		let tagName = this.el.nativeElement.localName;
		if ('a' === tagName) {
			tagName = 'link';
		} else if ('mat-expansion-panel' === tagName || 'op-expansion-panel' === tagName) {
			tagName = 'accordion';
		}
		return tagName;
	}
}
