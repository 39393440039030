import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { AppMaterialModule } from 'src/app/vendor/material-design/material-design.module';

import { AddAddressDialogComponent } from './components/add-address-dialog/add-address-dialog.component';
import { AddBankComponent } from './components/add-bank/add-bank.component';
import { AddBankExtComponent } from './components/add-bank-ext/add-bank-ext.component';
import { AddressDetailComponent } from './components/address-detail/address-detail.component';
import { AlertComponent } from './components/alert/alert.component';
import { AttentionItemComponent } from './components/attention-item/attention-item.component';
import { BackLinkComponent } from './components/back-link/back-link.component';
import { BadgeComponent } from './components/badge/badge.component';
import { BouncedAttentionComponent } from './components/bounced-attention/bounced-attention.component';
import { ContentContainerComponent } from './components/content-container/content-container.component';
import { CreditCardImgComponent } from './components/credit-card-img/credit-card-img.component';
import { CreditCardOfferComponent } from './components/credit-card-offer/credit-card-offer.component';
import { DateInputComponent } from './components/date-input/date-input.component';
import { AddressSelectionDialogComponent } from './components/dialogs/address-selection-dialog/address-selection-dialog.component';
import { BankVerificationDialogComponent } from './components/dialogs/bank-verification-dialog/bank-verification-dialog.component';
import { CheckDeliveryConfirmComponent } from './components/dialogs/check-delivery-confirm/check-delivery-confirm.component';
import { CheckDeliveryNoticeComponent } from './components/dialogs/check-delivery-notice/check-delivery-notice.component';
import { ContactUsDialogComponent } from './components/dialogs/contact-us-dialog/contact-us-dialog.component';
import { FindLocationDialogComponent } from './components/dialogs/find-location-dialog/find-location-dialog.component';
import { FindStoreDetailsComponent } from './components/dialogs/find-location-dialog/find-store-details/find-store-details.component';
import { FindStoreItemComponent } from './components/dialogs/find-location-dialog/find-store-item/find-store-item.component';
import { FundingMethodConfirmComponent } from './components/dialogs/funding-method-confirm/funding-method-confirm.component';
import { IdleTimeoutDialogComponent } from './components/dialogs/idle-timeout-dialog/idle-timeout-dialog.component';
import { MessageDialogComponent } from './components/dialogs/message-dialog/message-dialog.component';
import { MessageConfirmDialogComponent } from './components/dialogs/message-confirm-dialog/message-confirm-dialog.component';
import { PdfDialogComponent } from './components/dialogs/pdf-dialog/pdf-dialog.component';
import { DigitOfferForApprovedComponent } from './components/digit/digit-offer-for-approved/digit-offer-for-approved.component';
import { DigitOfferForNotApprovedComponent } from './components/digit/digit-offer-for-not-approved/digit-offer-for-not-approved.component';
import { ExpansionPanelComponent } from './components/expansion-panel/expansion-panel.component';
import { FileNameFormatterComponent } from './components/file-name-formatter/file-name-formatter.component';
import { FileThumbnailsComponent } from './components/file-thumbnails/file-thumbnails.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { HorizontalRuleComponent } from './components/horizontal-rule/horizontal-rule.component';
import { HowItWorksPanelComponent } from './components/how-it-works-panel/how-it-works-panel.component';
import { IncrementDecrementComponent } from './components/increment-decrement/increment-decrement.component';
import { InfoTipComponent } from './components/info-tip/info-tip.component';
import { InlineExpansionPanelComponent } from './components/inline-expansion-panel/inline-expansion-panel.component';
import { LayoutComponent } from './components/layout/layout.component';
import { LearnMoreComponent } from './components/learn-more/learn-more.component';
import { LoanDistributionTermComponent } from './components/loan-distribution-terms/loan-distribution-term.component';
import { ManuallyAddBankComponent } from './components/manually-add-bank/manually-add-bank.component';
import { ManuallyAddBankExtComponent } from './components/manually-add-bank-ext/manually-add-bank-ext.component';
import { AddRachBankAccountComponent } from './components/add-rach-bank-account/add-rach-bank-account.component';
import { MastheadComponent } from './components/masthead/masthead.component';
import { MicrobiltBankAccountComponent } from './components/microbilt-bank-account/microbilt-bank-account.component';
import { PlaidBankAccountComponent } from './components/plaid-bank-account/plaid-bank-account.component';
import { PlaidAccountListComponent } from './components/plaid-bank-transaction/plaid-account-list/plaid-account-list.component';
import { PlaidAccountSelectionListComponent } from './components/plaid-bank-transaction/plaid-account-selection-list/plaid-account-selection-list.component';
import { PlaidBankTransactionComponent } from './components/plaid-bank-transaction/plaid-bank-transaction.component';
import { PlaidOfferComponent } from './components/plaid-bank-transaction/plaid-offer/plaid-offer.component';
import { PlaidBannerComponent } from './components/plaid-banner/plaid-banner.component';
import { PlaidButtonComponent } from './components/plaid-button/plaid-button.component';
import { PlaidButtonExtComponent } from './components/plaid-button-ext/plaid-button-ext.component';
import { PlaidExtendedComponent } from './components/plaid-extended/plaid-extended.component';
import { SelectBankAccountComponent } from './components/select-bank-account/select-bank-account.component';
import { SelectBankAccountExtComponent } from './components/select-bank-account-ext/select-bank-account-ext.component';
import { SelectBankAccountAutoPayComponent } from './components/select-bank-account-auto-pay/select-bank-account-auto-pay.component';

import { SelfieUploadComponent } from './components/selfie-upload/selfie-upload.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { SlideToggleComponent } from './components/slide-toggle/slide-toggle.component';
import { SnackBarAlertComponent } from './components/snack-bar-alert/snack-bar-alert.component';
import { StepBaseComponent } from './components/stepper/step-base/step-base.component';
import { StepInfoComponent } from './components/stepper/step-info/step-info.component';
import { StepTitleComponent } from './components/stepper/step-title/step-title.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { UnidosUsInformationComponent } from './components/unidos-us-information/unidos-us-information.component';
import { DebitCardDisclaimerComponent } from './components/debit-card-disclaimer/debit-card-disclaimer.component';
import { PlaidTermsComponent } from './components/plaid-terms/plaid-terms.component';
import { WebsiteTermsComponent } from './components/website-terms/website-terms.component';
import { AddressAutoCompleteDirective } from './directives/address-auto-complete.directive';
import { OpMaxLengthDirective } from './directives/op-max-length/op-max-length.directive';
import { TagClickDirective } from './directives/tag-click/tag-click.directive';
import { TrackInputDirective } from './directives/track-input/track-input.directive';
import { DateFnsFormatPipe } from './pipes/date-fns-format/date-fns-format.pipe';
import { DayOrdinalPipe } from './pipes/day-ordinal/day-ordinal.pipe';
import { FinishedFileUploadsPipe } from './pipes/finished-file-uploads/finished-file-uploads.pipe';
import { GetMetadataTextPipe } from './pipes/get-metadata-text/get-metadata-text.pipe';
import { ShowIdentificationFieldPipe } from './pipes/identification/identification.pipe';
import { IsStateEqualPipe } from './pipes/is-state-equal/is-state-equal.pipe';
import { IsStatusEqualPipe } from './pipes/is-status-equal/is-status-equal.pipe';
import { LastNNumberMaskPipe } from './pipes/last-n-number-mask.pipe';
import { MetadataFilterPipe } from './pipes/metadata-filter/metadata-filter.pipe';
import { OpRequiredPipe } from './pipes/op-required/op-required.pipe';
import { PhoneNumberPipe } from './pipes/phone-number.pipe';
import { SafeHtmlPipe } from './pipes/safe-html/safe-html.pipe';
import { SingularPluralPipe, SingularPluralStringPipe } from './pipes/singularPlural/singular-plural.pipe';
import { AddManuallyComponent } from '../approval/add-bank-account/add-manually/add-manually.component';
import { AccountNotConnectedComponent } from './components/dialogs/account-not-connected/account-not-connected.component';
import { PromoCardComponent } from './components/promo-card/promo-card.component';
import { DebitCardFaqComponent } from './components/debit-card-faq/debit-card-faq.component';
import { DebitCardFaqDialogComponent } from './components/dialogs/debit-card-faq-dialog/debit-card-faq-dialog.component';
import { PlaidConnectDialogComponent } from './components/dialogs/plaid-connect-dialog/plaid-connect-dialog.component';

const sharedComponents = [
	AccountNotConnectedComponent,
	AddAddressDialogComponent,
	AddBankComponent,
	AddBankExtComponent,
	AddManuallyComponent,
	AddRachBankAccountComponent,
	AddressAutoCompleteDirective,
	AddressDetailComponent,
	AddressSelectionDialogComponent,
	DebitCardFaqComponent,
	DebitCardFaqDialogComponent,
	AlertComponent,
	AttentionItemComponent,
	BackLinkComponent,
	BadgeComponent,
	BankVerificationDialogComponent,
	BouncedAttentionComponent,
	CheckDeliveryConfirmComponent,
	CheckDeliveryNoticeComponent,
	ContactUsDialogComponent,
	ContentContainerComponent,
	CreditCardImgComponent,
	CreditCardImgComponent,
	CreditCardOfferComponent,
	DateFnsFormatPipe,
	DateInputComponent,
	DayOrdinalPipe,
	DigitOfferForApprovedComponent,
	DigitOfferForNotApprovedComponent,
	ExpansionPanelComponent,
	FileNameFormatterComponent,
	FileThumbnailsComponent,
	FileUploadComponent,
	FindLocationDialogComponent,
	FindStoreDetailsComponent,
	FindStoreItemComponent,
	FinishedFileUploadsPipe,
	FooterComponent,
	FundingMethodConfirmComponent,
	GetMetadataTextPipe,
	HeaderComponent,
	HorizontalRuleComponent,
	HowItWorksPanelComponent,
	IdleTimeoutDialogComponent,
	IncrementDecrementComponent,
	InfoTipComponent,
	InlineExpansionPanelComponent,
	IsStateEqualPipe,
	IsStatusEqualPipe,
	LayoutComponent,
	LearnMoreComponent,
	LoanDistributionTermComponent,
	ManuallyAddBankComponent,
	ManuallyAddBankExtComponent,
	MastheadComponent,
	MessageDialogComponent,
	MessageConfirmDialogComponent,
	LastNNumberMaskPipe,
	MetadataFilterPipe,
	MicrobiltBankAccountComponent,
	OpMaxLengthDirective,
	OpRequiredPipe,
	PdfDialogComponent,
	PhoneNumberPipe,
	PlaidAccountListComponent,
	PlaidAccountSelectionListComponent,
	PlaidBankAccountComponent,
	PlaidBankTransactionComponent,
	PlaidBannerComponent,
	PlaidButtonExtComponent,
	PlaidButtonComponent,
	PlaidExtendedComponent,
	PlaidConnectDialogComponent,
	PlaidOfferComponent,
	PlaidTermsComponent,
	PromoCardComponent,
	SafeHtmlPipe,
	SelectBankAccountComponent,
	SelectBankAccountExtComponent,
	SelectBankAccountAutoPayComponent,
	SelfieUploadComponent,
	ShowIdentificationFieldPipe,
	SideNavComponent,
	SingularPluralPipe,
	SingularPluralStringPipe,
	SlideToggleComponent,
	SnackBarAlertComponent,
	StepBaseComponent,
	StepInfoComponent,
	StepperComponent,
	StepTitleComponent,
	TagClickDirective,
	TrackInputDirective,
	UnidosUsInformationComponent,
	WebsiteTermsComponent,
	DebitCardDisclaimerComponent
];

/**
 * Shared Components, directives, and pipes.
 *
 * @export
 * @class SharedModule
 */
@NgModule({
	imports: [
		CommonModule,
		TranslocoModule,
		AppMaterialModule,
		ReactiveFormsModule,
		NgxExtendedPdfViewerModule,
		RouterModule
	],
	declarations: [...sharedComponents],
	exports: [CommonModule, TranslocoModule, AppMaterialModule, ReactiveFormsModule, RouterModule, ...sharedComponents]
})
export class SharedModule {}
