import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ILoanApplication } from 'src/app/core/services/loan-application/loan-application.model';
import { IEligibleProducts, IMultiProductResult } from 'src/app/core/services/mobile-api';
import { MultiProductService, ProductTypeEnum } from 'src/app/core/services/multi-product/multi-product.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';
import { OrganizationUtils } from 'src/app/core/utils/organization-utils';
import { DigitUtils } from 'src/app/core/utils/digit-utils';

@Component({
	selector: 'op-status-not-approved',
	templateUrl: './not-approved.component.html',
	styleUrls: ['./not-approved.component.scss']
})
export class NotApprovedComponent implements OnInit, OnDestroy {
	private _loanApp: ILoanApplication;
	get loanApp(): ILoanApplication {
		return this._loanApp;
	}

	@Input()
	set loanApp(value: ILoanApplication) {
		this._loanApp = value;
	}

	constructor(private multiProductService: MultiProductService, private tagDataService: TagDataService) {}

	body: string;
	newApplicationStartDate: Date;
	subTitle: string;
	title: string;
	issuingOrganizationIsOportun: boolean;
	issuingOrganizationIsMeta: boolean;

	private subscription = new Subscription();
	digitUrl: string;

	ngOnInit(): void {
		this.body = `STATUS.${this.loanApp.applicationStatus}.body`;
		this.newApplicationStartDate = new Date();
		this.subTitle = `STATUS.${this.loanApp.applicationStatus}.initial`;
		this.title = `STATUS.${this.loanApp.applicationStatus}.title.${this.loanApp.issuingOrganization}`;
		this.issuingOrganizationIsOportun = OrganizationUtils.isOportun(this.loanApp.issuingOrganization);
		this.issuingOrganizationIsMeta = OrganizationUtils.isMetaBank(this.loanApp.issuingOrganization);
		const multiProductSub = this.multiProductService.checkMultiProductEligibility(this.loanApp.id).subscribe({
			next: (response: IMultiProductResult) => {
				this.digitUrl = this.getDigitUrl(response);
			}
		});
		this.subscription.add(multiProductSub);
	}

	public trackEligibilityEvents(eventLabel: string): void {
		const tealiumEvent = {
			tealium_event: 'check_multiproduct_eligible',
			event_category: 'CONSUMER_INSTALLMENT_LOAN',
			event_label: eventLabel
		};
		if (eventLabel === 'digit_offer_clicked') {
			tealiumEvent['link_href'] = this.digitUrl;
		}
		this.tagDataService.link({}, tealiumEvent);
	}

	private getDigitUrl(response: IMultiProductResult): string {
		let digit_url = null;
		if (response.success && response.multiProductEligible) {
			if (response.eligibleProducts?.length) {
				const digitProduct: IEligibleProducts = response.eligibleProducts.find(
					(item) => item.productType.toLowerCase() === ProductTypeEnum.digit
				);
				if (digitProduct) {
					this.trackEligibilityEvents('digit_eligible');
					digit_url = digitProduct.redirectUrl;
					const tealium_vid = this.tagDataService.getTeliumVisitorId();
					if (tealium_vid) {
						digit_url = DigitUtils.getDigitUrl(digit_url, tealium_vid, this.issuingOrganizationIsMeta);
					}
					this.trackEligibilityEvents('digit_offer_shown');
				}
			}
		}
		return digit_url;
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
