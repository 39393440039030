import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, of, Subscription } from 'rxjs';
import { FileUploadType } from 'src/app/core/services/file-upload/file-upload.service';
import { DocumentStatusCriteriaEnum, IMetadata } from 'src/app/core/services/mobile-api';
import { RoutingService } from 'src/app/core/services/routing/routing.service';

import { collateFileInfo, CollationService } from '../collation.service';
import { CollationSelectionComponent } from './collation-selection/collation-selection.component';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { IMessageDialogData } from 'src/app/shared/components/dialogs/message-dialog/message-dialog.component';
import { LanguageService } from 'src/app/core/services/language/language.service';
import { SessionStorageService } from 'src/app/core/services/storage/session-storage.service';
import AutoVerificationUtils from '../auto-verification-utils';
import { DateUtilsService } from 'src/app/core/services/date-utils/date-utils.service';

@Component({
	selector: 'op-document-collation',
	templateUrl: './document-collation.component.html',
	styleUrls: ['./document-collation.component.scss']
})
export class DocumentCollationComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild(CollationSelectionComponent) collationSelection: CollationSelectionComponent;

	readonly maxDocumentCount = 10;
	// note: Only an estimate is created so leave some buffer ~ 3MB
	readonly maxDocumentSize = 22 * 1024 * 1024; // 22 MB

	public criteriaEnum: typeof DocumentStatusCriteriaEnum = DocumentStatusCriteriaEnum;

	protected subscription: Subscription = new Subscription();

	categoryType: FileUploadType;
	documentClassification: IMetadata;
	criteria: DocumentStatusCriteriaEnum;
	uploadDisabled: boolean;
	documentCount = 0;
	documentSize = 0;
	disableAddMore = false;
	storageKey = 'document-collation';
	dateRangeString = '';

	constructor(
		private routingService: RoutingService,
		private collationService: CollationService,
		private activatedRoute: ActivatedRoute,
		private dialogService: DialogService,
		private languageService: LanguageService,
		private sessionStorageService: SessionStorageService,
		private dateUtilsService: DateUtilsService
	) {
		const langSub = this.languageService.langChanges$.subscribe({
			next: (lang) => {
				const locale = this.dateUtilsService.getLocale(lang);
				this.dateRangeString =
					this.criteria === DocumentStatusCriteriaEnum.zeroToThirtyDays
						? AutoVerificationUtils.get30DayString(locale)
						: AutoVerificationUtils.get60DayString(locale);
			}
		});
		this.subscription.add(langSub);
	}

	ngOnInit(): void {
		const stateSub = this.activatedRoute.paramMap.pipe(map(() => window.history.state)).subscribe({
			next: (state) => {
				if (!state?.criteria) {
					state = this.sessionStorageService.get(this.storageKey);
				} else {
					this.sessionStorageService.set(this.storageKey, state);
				}
				this.categoryType = state?.categoryType;
				this.documentClassification = state?.documentClassification;
				this.criteria = state?.criteria;
				const locale = this.dateUtilsService.getCurrentLocale();
				this.dateRangeString =
					this.criteria === DocumentStatusCriteriaEnum.zeroToThirtyDays
						? AutoVerificationUtils.get30DayString(locale)
						: AutoVerificationUtils.get60DayString(locale);
			}
		});
		this.subscription.add(stateSub);

		const sub = this.collationService.collation$.subscribe({
			next: (fileList) => {
				this.documentCount = fileList.length;
				this.uploadDisabled = fileList.length === 0;
				this.documentSize = this.calculateFileSizes(fileList);
				this.disableAddMore = this.documentCount >= this.maxDocumentCount || this.documentSize >= this.maxDocumentSize;
				if (this.documentSize >= this.maxDocumentSize) {
					this.uploadDisabled = true;
				}
			}
		});
		this.subscription.add(sub);
	}

	ngAfterViewInit() {
		this.collationSelection.onAddMore();
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
		this.sessionStorageService.remove(this.storageKey);
	}

	private calculateFileSizes(files: collateFileInfo[]): number {
		return files.reduce((acc, file) => acc + file.file.size, 0);
	}

	onBack(upload: boolean): void {
		const extra = {
			state: {
				categoryType: this.categoryType,
				documentClassification: this.documentClassification,
				criteria: this.criteria,
				upload
			}
		};
		this.routingService.back(extra);
	}

	onContinue(): void {
		const dlgData: IMessageDialogData = {
			title: '',
			message: this.languageService.translate('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIncome.collation.dialog.content'),
			confirmText: this.languageService.translate('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIncome.collation.addMore'),
			cancelText: this.languageService.translate(
				'DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIncome.collation.dialog.skipProceed'
			)
		};
		this.dialogService
			.openMessageDialog(
				dlgData,
				(value) => {
					return of(true);
				},
				(value) => {
					return value === undefined ? of(true) : of(false);
				}
			)
			.subscribe({
				next: (result) => {
					!result && this.onBack(true);
				}
			});
	}
}
